import React, { PureComponent } from 'react'
import { createFragmentContainer} from 'react-relay';
import { withRelayContext, withPageIndex } from 'containers/App'
import { graphql } from 'babel-plugin-relay/macro'
import MediaQuery from 'react-responsive'
import ViewerSplit from 'components_simple/ViewerSplit';
import styles from './index.module.css'
import {Helmet} from "react-helmet";

import AddHtml from 'components_simple/AddHtml'

class About extends PureComponent {
  // state = {
    
  // }

  componentDidMount() {
    // this.props.changeTheme("dark")
    this.props.updatePageIndex('empty')
  }

  // componentWillUnmount() {
  //   this.props.changeTheme("bright")
  // }

  render() {
    const description = this.props.viewer.categoryAbout.filter(item => item.name === "Description")[0].description
    const contact = this.props.viewer.categoryAbout.filter(item => item.name === "Contact")[0].description

    return (
      <div className={styles.main}>
        <Helmet>
          <title>VEJOME - About</title>
        </Helmet>
        <ViewerSplit>
          <div className={styles.left}>
            <MediaQuery maxWidth={1023}>
              <div className={styles.rest}>
                <AddHtml html={contact} />
              </div>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
              <div className={styles.about}>
                <AddHtml html={description} />
              </div>
            </MediaQuery>
          </div>
          <div className={styles.right}>
            <MediaQuery maxWidth={1023}>
              <div className={styles.about}>
              <AddHtml html={description} />
              </div>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
              <div className={styles.rest}>
                <AddHtml html={contact} />
              </div>
            </MediaQuery>
          </div>
        </ViewerSplit>
      </div>
    )
  }
}


export default withPageIndex(withRelayContext(createFragmentContainer(
  About,
  graphql`
  fragment About_viewer on Viewer {
    categoryAbout: categoriesShop (node: "About") {
      name
      description
    }
  }
  `
))) 