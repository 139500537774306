import React, { PureComponent } from 'react';
import styles from './index.module.css';
import { createFragmentContainer } from 'react-relay';
import { withRelayContext, withPageIndex } from 'containers/App';
import { graphql } from 'babel-plugin-relay/macro';
import classNames from 'classnames/bind';
import { Formik, Form, ErrorMessage } from 'formik';
import MediaQuery from 'react-responsive';
import { Helmet } from 'react-helmet';

import DropDownList from 'containers/DropDownList';
import ShopDetailProductInformation from 'containers/ShopDetailProductInformation';
import AddProductToBasketMutation from 'mutations/AddProductToBasketMutation';
import ImageCarousel from 'containers/ImageCarousel';

const cx = classNames.bind(styles);

class ShopDetailProduct extends PureComponent {
  state = {
    informationOpen: false,
    material: '',
    size: '',
    productID: '',
    categoryArray: [],
    formError: '',
    optionIndex: 0
  };

  componentDidMount() {
    this.mountUpdateCategories();
    this.props.updatePageIndex('productDetail');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.product !== prevProps.product) {
      this.mountUpdateCategories();
    }
    if (this.state.material !== prevState.material) {
      this.mountUpdateCategories();
    }
  }

  mountUpdateCategories = () => {
    let {
      title,
      productClass: {
        attributes: { edges: categories }
      },
      description,
      children: { edges: children },
      available,
      tax,
      shippingInformation
    } = this.props.product;

    const { productID } = this.state;

    const { original: image } = this.props.product.images.edges[0].node;

    const activeProduct = children.findIndex(child => child.node.id === productID);
    const activeProductIndex = activeProduct !== -1 ? activeProduct : 0;

    const price =
      children && children.length > activeProductIndex
        ? children[activeProductIndex].node.attributeValues.edges[0].node.productInStock.priceExclTax * (tax + 1)
        : 0;

    let categoryArray = [];

    const filterProducts = children
      .map(item => {
        let filter = item.node.attributeValues.edges.filter(node => {
          return node.node.attributeOption.option === this.state.material;
        });
        if (filter.length > 0) {
          return filter[0].node.product.title;
        } else {
          return false;
        }
      })
      .filter(item => {
        return item !== false;
      });

    categories.forEach((category, index) => {
      const variable = children
        .filter(item => {
          let filter = [];

          if (item.node.available === false) {
            return false;
          }

          if (index === 0) {
            filter = item.node.attributeValues.edges.filter(node => {
              return node.node.attribute.name === category.node.name;
            });
          } else {
            filter = item.node.attributeValues.edges.filter(node => {
              let product = filterProducts.filter(item => {
                return item.toLowerCase() === node.node.product.title.toLowerCase();
              });
              if (product.length > 0) {
                product = true;
              } else {
                product = false;
              }

              return product & (node.node.attribute.name === category.node.name);
            });
          }
          return filter.length > 0;
        })
        .map(item => {
          let newItem = Object.assign({}, item, {
            node: {
              ...item.node,
              attributeValues: {
                edges: item.node.attributeValues.edges.filter(node => {
                  return node.node.attribute.name === category.node.name;
                })
              }
            }
          });
          return newItem;
        });
      categoryArray.push(variable); //item.node.name
    });

    this.setState({
      title,
      categories,
      description,
      children,
      image,
      categoryArray,
      available,
      price,
      shippingInformation
    });
  };

  toggleOpen = () => {
    this.setState(prevState => ({
      informationOpen: !prevState.informationOpen
    }));
  };

  selectProductType = (type, value) => {
    this.setState({
      [type]: value
    });
  };

  setOptionMaterialIndex = index => {
    let {
      children: { edges: children },
      tax
    } = this.props.product;

    const price =
      children && children.length > index
        ? children[index].node.attributeValues.edges[0].node.productInStock.priceExclTax * (tax + 1)
        : 0;

    this.setState({
      optionIndex: index,
      price
    });
  };

  render() {
    const {
      informationOpen,
      categoryArray,
      categories,
      description,
      title,
      available,
      price,
      // shippingInformation,
      optionIndex,
      productID
    } = this.state;
    // console.log('this.state: ', this.state);
    const { product } = this.props;
    const allImages = [...this.props.product.images.edges, ...this.props.product.lookbookImagesMobile.edges];
    const productImages = [...this.props.product.images.edges];
    const productCategories = categoryArray;

    const activeChild = product && product.children.edges.find(child => child.node.id === productID);
    const shippingInformationText = activeChild && activeChild.node.shippingInformation;

    return (
      <div className={styles.main}>
        <Helmet>
          <title>{`VEJOME - ${title}`}</title>
          <meta name="description" content={description} />
        </Helmet>
        <MediaQuery minWidth={1024}>
          {informationOpen ? (
            <ShopDetailProductInformation title={title} description={description} extra={categories} />
          ) : (
            <span className={styles.image}>
              <ImageCarousel
                selector={false}
                arrows={false}
                splitClick={false}
                firstContain={true}
                images={productImages}
                currentImageIndex={optionIndex}
                containNumber={productImages.length}
              />
            </span>
          )}
        </MediaQuery>
        <MediaQuery maxWidth={1023}>
          <span className={styles.upperFrame}>
            {informationOpen ? (
              <ShopDetailProductInformation title={title} description={description} extra={categories} />
            ) : (
              <span className={styles.image}>
                <ImageCarousel
                  selector={false}
                  arrows={true}
                  splitClick={true}
                  firstContain={true}
                  images={allImages}
                  currentImageIndex={optionIndex}
                  containNumber={productImages.length}
                />
              </span>
            )}
          </span>
        </MediaQuery>
        <MediaQuery minWidth={1024}>
          <div className={styles.shippingInformation}>{shippingInformationText}</div>
        </MediaQuery>
        <Formik
          initialValues={{ size: '', material: '' }}
          validate={values => {
            let errors = {};
            const lenCategory = categoryArray.length;
            if (!values.material) {
              errors.material = 'Please select Material';
              this.setState({
                formError: errors.material
              });
            }
            if (values.material && !values.size && lenCategory > 1) {
              errors.size = 'Please select Size';
              this.setState({
                formError: errors.size
              });
            }

            if (values.material && values.size && lenCategory > 1) {
              this.setState({
                formError: false
              });
            }

            if (values.material && lenCategory === 1) {
              this.setState({
                formError: false
              });
            }

            return errors;
          }}
          onSubmit={values => {
            AddProductToBasketMutation(values, this.props.viewer.id, result => {
              // if (result.error) {
              //   this.setState({
              //     errorMessage: result.errorMessage,
              //     error: result.error
              //   })
              // }
            });
          }}
          render={props => (
            <Form>
              <div className={styles.form}>
                {productCategories.map((category, index) => {
                  const itemClass = cx(`item${index + 1}`);

                  if (category.length < 1 && available) {
                    return null;
                  }

                  if (available) {
                    return (
                      <div className={itemClass} key={index}>
                        <DropDownList
                          title={category[0].node.attributeValues.edges[0].node.attribute.name}
                          list={category}
                          selectProductType={this.selectProductType}
                          handleChange={props.handleChange}
                          setFieldValue={props.setFieldValue}
                          setOptionMaterialIndex={this.setOptionMaterialIndex}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div style={{ color: '#ea5151', textAlign: 'center' }} className={styles.item1}>
                        SOLD OUT
                      </div>
                    );
                  }
                })}
                <div className={styles.information} onClick={this.toggleOpen}>
                  {title} {informationOpen ? ' -' : ' +'}{' '}
                </div>
                <MediaQuery maxWidth={1023}>
                  <div style={{ gridColumn: !available ? '1 / span 1' : '1 / span 1' }} className={styles.price}>
                    {parseFloat(price).toFixed(2)} €
                  </div>
                </MediaQuery>
                <MediaQuery minWidth={1024}>
                  <div style={{ gridColumn: !available ? '4 / span 2' : '4 / span 1' }} className={styles.price}>
                    {parseFloat(price).toFixed(2)} €
                  </div>
                </MediaQuery>
                {available && (
                  <div className={styles.addToCartDiv}>
                    <div className={styles.formError}>
                      <ErrorMessage name="material" />
                      <ErrorMessage name="size" />
                    </div>
                    <button type="submit" className={styles.addToCart}>
                      Add To Cart
                    </button>
                    <MediaQuery maxWidth={1023}>
                      <div className={styles.shippingInformation}>{shippingInformationText}</div>
                    </MediaQuery>
                  </div>
                )}
              </div>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default withPageIndex(
  withRelayContext(
    createFragmentContainer(
      ShopDetailProduct,
      graphql`
        fragment ShopDetailProduct_product on ProductNode {
          tax
          title
          id
          description
          available
          shippingInformation
          images {
            edges {
              node {
                original
                id
              }
            }
          }
          lookbookImagesMobile {
            edges {
              node {
                original
                id
              }
            }
          }
          children {
            edges {
              node {
                id
                title
                available
                shippingInformation
                attributeValues {
                  edges {
                    node {
                      product {
                        id
                        title
                      }
                      productInStock {
                        priceExclTax
                        numInStock
                      }
                      attribute {
                        id
                        name
                      }
                      attributeOption {
                        id
                        option
                      }
                    }
                  }
                }
              }
            }
          }
          productClass {
            name
            attributes {
              edges {
                node {
                  id
                  name
                  optionGroup {
                    options {
                      edges {
                        node {
                          id
                          option
                          description
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `
    )
  )
);
