/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type ProductLandingpageImageOrder = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type Product_product$ref: FragmentReference;
export type Product_product = {|
  +title: string,
  +slug: string,
  +landingpageImage: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +original: string,
        +order: ProductLandingpageImageOrder,
      |}
    |}>
  |},
  +children: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +title: string,
        +productInStock: ?{|
          +priceExclTax: ?number
        |},
      |}
    |}>
  |},
  +$refType: Product_product$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "Product_product",
  "type": "ProductNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "landingpageImage",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductLandingpageImageNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductLandingpageImageNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ProductLandingpageImageNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "original",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "order",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "children",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ProductNode",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "productInStock",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "StockRecordNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "priceExclTax",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b2f812d3ed794fa4a2a5c5f7f5f1909c';
module.exports = node;
