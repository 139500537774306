/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ShopDetailProduct_product$ref: FragmentReference;
export type ShopDetailProduct_product = {|
  +tax: ?number,
  +title: string,
  +id: string,
  +description: string,
  +available: boolean,
  +shippingInformation: ?string,
  +images: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +original: string,
        +id: string,
      |}
    |}>
  |},
  +lookbookImagesMobile: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +original: string,
        +id: string,
      |}
    |}>
  |},
  +children: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: string,
        +available: boolean,
        +shippingInformation: ?string,
        +attributeValues: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +product: {|
                +id: string,
                +title: string,
              |},
              +productInStock: ?{|
                +priceExclTax: ?number,
                +numInStock: ?number,
              |},
              +attribute: {|
                +id: string,
                +name: string,
              |},
              +attributeOption: ?{|
                +id: string,
                +option: string,
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +productClass: ?{|
    +name: string,
    +attributes: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +optionGroup: ?{|
            +options: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +option: string,
                  +description: string,
                |}
              |}>
            |}
          |},
        |}
      |}>
    |},
  |},
  +$refType: ShopDetailProduct_product$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "available",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "shippingInformation",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "original",
    "args": null,
    "storageKey": null
  },
  v1
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "option",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ShopDetailProduct_product",
  "type": "ProductNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tax",
      "args": null,
      "storageKey": null
    },
    v0,
    v1,
    v2,
    v3,
    v4,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "images",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductImageNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductImageNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ProductImageNode",
              "plural": false,
              "selections": v5
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "lookbookImagesMobile",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductLookbookImageMobileNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductLookbookImageMobileNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ProductLookbookImageMobileNode",
              "plural": false,
              "selections": v5
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "children",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ProductNode",
              "plural": false,
              "selections": [
                v1,
                v0,
                v3,
                v4,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "attributeValues",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ProductAttributeValueNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ProductAttributeValueNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ProductAttributeValueNode",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "product",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "ProductNode",
                              "plural": false,
                              "selections": [
                                v1,
                                v0
                              ]
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "productInStock",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "StockRecordNode",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "priceExclTax",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "numInStock",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "attribute",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "ProductAttributeNode",
                              "plural": false,
                              "selections": [
                                v1,
                                v6
                              ]
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "attributeOption",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "AttributeOptionNode",
                              "plural": false,
                              "selections": [
                                v1,
                                v7
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "productClass",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductClassNode",
      "plural": false,
      "selections": [
        v6,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "attributes",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductAttributeNodeConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "ProductAttributeNodeEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ProductAttributeNode",
                  "plural": false,
                  "selections": [
                    v1,
                    v6,
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "optionGroup",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "AttributeOptionGroupNode",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "options",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "AttributeOptionNodeConnection",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "edges",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "AttributeOptionNodeEdge",
                              "plural": true,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "node",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "AttributeOptionNode",
                                  "plural": false,
                                  "selections": [
                                    v1,
                                    v7,
                                    v2
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '890fe83e6c9a29faea5e4cce582d9830';
module.exports = node;
