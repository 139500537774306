import React from 'react'
import sanitizeHtml from 'sanitize-html';

const AddHtml = ({ html:dirty }) => {
  const clean = sanitizeHtml(dirty, {
    allowedTags: ['p', 'b', 'i', 'em', 'strong', 'a', 'br', 'h2', 'h3'],
    allowedAttributes: {
      a: ['href', 'target']
    }
  })
  return (
    <div dangerouslySetInnerHTML={{__html: clean}}/>
  )
}

export default AddHtml