import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.css'

const ViewerSplit = (props) => {
  return (
    <div className={styles.main}>
      {props.children}
    </div>
  )
}

ViewerSplit.propercentagepTypes = {
  props: PropTypes.object
}

export default ViewerSplit