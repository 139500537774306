/**
 * @flow
 * @relayHash f844ab88d990300b6c7ab4f5976119ae
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddProductToBasketInput = {
  productID: string,
  material?: ?string,
  size?: ?string,
  imageIndex?: ?number,
  clientMutationId?: ?string,
};
export type AddProductToBasketMutationVariables = {|
  input: AddProductToBasketInput
|};
export type AddProductToBasketMutationResponse = {|
  +addProductToBasket: ?{|
    +productID: ?string,
    +basket: ?{|
      +getTotalInclTax: ?string,
      +getTotalTax: ?string,
      +getTotalItems: ?string,
      +lines: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +imageIndex: number,
            +product: {|
              +id: string,
              +attributeValues: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +attributeOption: ?{|
                      +option: string
                    |},
                    +valueMultiOption: ?{|
                      +edges: $ReadOnlyArray<?{|
                        +node: ?{|
                          +option: string,
                          +group: {|
                            +name: string
                          |},
                        |}
                      |}>
                    |},
                  |}
                |}>
              |},
              +parent: ?{|
                +title: string,
                +images: ?{|
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +original: string
                    |}
                  |}>
                |},
              |},
            |},
            +priceExclTax: ?number,
            +priceInclTax: ?number,
            +quantity: number,
            +priceCurrency: string,
          |}
        |}>
      |},
    |},
  |}
|};
export type AddProductToBasketMutation = {|
  variables: AddProductToBasketMutationVariables,
  response: AddProductToBasketMutationResponse,
|};
*/


/*
mutation AddProductToBasketMutation(
  $input: AddProductToBasketInput!
) {
  addProductToBasket(input: $input) {
    productID
    basket {
      getTotalInclTax
      getTotalTax
      getTotalItems
      lines {
        edges {
          node {
            imageIndex
            product {
              id
              attributeValues {
                edges {
                  node {
                    attributeOption {
                      option
                      id
                    }
                    valueMultiOption {
                      edges {
                        node {
                          option
                          group {
                            name
                            id
                          }
                          id
                        }
                      }
                    }
                    id
                  }
                }
              }
              parent {
                title
                images {
                  edges {
                    node {
                      original
                      id
                    }
                  }
                }
                id
              }
            }
            priceExclTax
            priceInclTax
            quantity
            priceCurrency
            id
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddProductToBasketInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "AddProductToBasketInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "productID",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "getTotalInclTax",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "getTotalTax",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "getTotalItems",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "imageIndex",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "option",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "original",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "priceExclTax",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "priceInclTax",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "priceCurrency",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "AddProductToBasketMutation",
  "id": null,
  "text": "mutation AddProductToBasketMutation(\n  $input: AddProductToBasketInput!\n) {\n  addProductToBasket(input: $input) {\n    productID\n    basket {\n      getTotalInclTax\n      getTotalTax\n      getTotalItems\n      lines {\n        edges {\n          node {\n            imageIndex\n            product {\n              id\n              attributeValues {\n                edges {\n                  node {\n                    attributeOption {\n                      option\n                      id\n                    }\n                    valueMultiOption {\n                      edges {\n                        node {\n                          option\n                          group {\n                            name\n                            id\n                          }\n                          id\n                        }\n                      }\n                    }\n                    id\n                  }\n                }\n              }\n              parent {\n                title\n                images {\n                  edges {\n                    node {\n                      original\n                      id\n                    }\n                  }\n                }\n                id\n              }\n            }\n            priceExclTax\n            priceInclTax\n            quantity\n            priceCurrency\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AddProductToBasketMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addProductToBasket",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddProductToBasketPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "basket",
            "storageKey": null,
            "args": null,
            "concreteType": "BasketNode",
            "plural": false,
            "selections": [
              v3,
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "lines",
                "storageKey": null,
                "args": null,
                "concreteType": "LineNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LineNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LineNode",
                        "plural": false,
                        "selections": [
                          v6,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "product",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProductNode",
                            "plural": false,
                            "selections": [
                              v7,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "attributeValues",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ProductAttributeValueNodeConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ProductAttributeValueNodeEdge",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "node",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "ProductAttributeValueNode",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "attributeOption",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "AttributeOptionNode",
                                            "plural": false,
                                            "selections": [
                                              v8
                                            ]
                                          },
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "valueMultiOption",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "AttributeOptionNodeConnection",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "edges",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "AttributeOptionNodeEdge",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "node",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "AttributeOptionNode",
                                                    "plural": false,
                                                    "selections": [
                                                      v8,
                                                      {
                                                        "kind": "LinkedField",
                                                        "alias": null,
                                                        "name": "group",
                                                        "storageKey": null,
                                                        "args": null,
                                                        "concreteType": "AttributeOptionGroupNode",
                                                        "plural": false,
                                                        "selections": [
                                                          v9
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "parent",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ProductNode",
                                "plural": false,
                                "selections": [
                                  v10,
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "images",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ProductImageNodeConnection",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "edges",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "ProductImageNodeEdge",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "node",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "ProductImageNode",
                                            "plural": false,
                                            "selections": [
                                              v11
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          v12,
                          v13,
                          v14,
                          v15
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddProductToBasketMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addProductToBasket",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddProductToBasketPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "basket",
            "storageKey": null,
            "args": null,
            "concreteType": "BasketNode",
            "plural": false,
            "selections": [
              v3,
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "lines",
                "storageKey": null,
                "args": null,
                "concreteType": "LineNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LineNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LineNode",
                        "plural": false,
                        "selections": [
                          v6,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "product",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProductNode",
                            "plural": false,
                            "selections": [
                              v7,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "attributeValues",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ProductAttributeValueNodeConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ProductAttributeValueNodeEdge",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "node",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "ProductAttributeValueNode",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "attributeOption",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "AttributeOptionNode",
                                            "plural": false,
                                            "selections": [
                                              v8,
                                              v7
                                            ]
                                          },
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "valueMultiOption",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "AttributeOptionNodeConnection",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "edges",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "AttributeOptionNodeEdge",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "node",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "AttributeOptionNode",
                                                    "plural": false,
                                                    "selections": [
                                                      v8,
                                                      {
                                                        "kind": "LinkedField",
                                                        "alias": null,
                                                        "name": "group",
                                                        "storageKey": null,
                                                        "args": null,
                                                        "concreteType": "AttributeOptionGroupNode",
                                                        "plural": false,
                                                        "selections": [
                                                          v9,
                                                          v7
                                                        ]
                                                      },
                                                      v7
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          },
                                          v7
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "parent",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ProductNode",
                                "plural": false,
                                "selections": [
                                  v10,
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "images",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ProductImageNodeConnection",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "edges",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "ProductImageNodeEdge",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "node",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "ProductImageNode",
                                            "plural": false,
                                            "selections": [
                                              v11,
                                              v7
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  v7
                                ]
                              }
                            ]
                          },
                          v12,
                          v13,
                          v14,
                          v15,
                          v7
                        ]
                      }
                    ]
                  }
                ]
              },
              v7
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9a5b3e13e782fa1fcf954935785b4692';
module.exports = node;
