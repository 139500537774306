/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Policies_viewer$ref: FragmentReference;
export type Policies_viewer = {|
  +categoriesShop: ?$ReadOnlyArray<?{|
    +name: string,
    +description: string,
  |}>,
  +$refType: Policies_viewer$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "Policies_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "categoriesShop",
      "storageKey": "categoriesShop(node:\"Policies & services\")",
      "args": [
        {
          "kind": "Literal",
          "name": "node",
          "value": "Policies & services",
          "type": "String"
        }
      ],
      "concreteType": "CategoryNode",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '7d608940f273f524e762f002f2ecade8';
module.exports = node;
