import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Helmet } from 'react-helmet';
import LoadingScreen from 'components_simple/LoadingScreen';

import environment from 'environment';
import styles from './index.module.css';

import Frame from 'containers/Frame';
import Home from 'containers/Home';
import Shop from 'containers/Shop';
import About from 'containers/About';
import Imprint from 'containers/Imprint';
import Policies from 'containers/Policies';

import RouteWithTheme from 'components_simple/RouteWithTheme'

// import CLIENT from 'containers/ShoppingCart';

export const AppContext = React.createContext({
  cartCount: null,
  cartTotalSum: null,
  updateCart: () => {},
  menuVisible: false,
  updateMenuVisible: () => {},
  pageIndex: null,
  updatePageIndex: () => {},
  loginName: false,
  updateLogin: () => {},
  imagesLoaded: true,
  updateImagesLoaded: () => {},
  shopFilter: 'all items',
  updateShopFilter: () => {},
  viewMenu: false,
  updateViewMenu: () => {},
  viewCart: false,
  updateViewCart: () => {},
  theme: 'bright',
  changeTheme: () => {}
});

export const RelayContext = React.createContext({
  viewer: null
});

export function withPageIndex(Component) {
  return function AppContextComponent(props) {
    return (
      <AppContext.Consumer>
        {({ updatePageIndex, pageIndex, viewMenu, updateViewMenu, viewCart, updateViewCart, theme, changeTheme }) => (
          <Component
            {...props}
            updatePageIndex={updatePageIndex}
            pageIndex={pageIndex}
            viewMenu={viewMenu}
            updateViewMenu={updateViewMenu}
            viewCart={viewCart}
            updateViewCart={updateViewCart}
            theme={theme}
            changeTheme={changeTheme}
          />
        )}
      </AppContext.Consumer>
    );
  };
}

export function withRelayContext(Component) {
  return function RelayContextComponent(props) {
    return <RelayContext.Consumer>{({ viewer }) => <Component {...props} viewer={viewer} />}</RelayContext.Consumer>;
  };
}

export function withLoginContext(Component) {
  return function LoginContextComponent(props) {
    return (
      <AppContext.Consumer>
        {({ loginName, updateLogin }) => <Component {...props} updateLogin={updateLogin} loginName={loginName} />}
      </AppContext.Consumer>
    );
  };
}

export function withLoadingContext(Component) {
  return function LoadingComponent(props) {
    return (
      <AppContext.Consumer>
        {({ imagesLoaded, updateImagesLoaded }) => (
          <Component {...props} imagesLoaded={imagesLoaded} updateImagesLoaded={updateImagesLoaded} />
        )}
      </AppContext.Consumer>
    );
  };
}

export function withShopFilterContext(Component) {
  return function ShopFilterComponent(props) {
    return (
      <AppContext.Consumer>
        {({ shopFilter, updateShopFilter }) => (
          <Component {...props} shopFilter={shopFilter} updateShopFilter={updateShopFilter} />
        )}
      </AppContext.Consumer>
    );
  };
}

const screen = () => {
  const screenHeight = window.screen.height;
  const screenWidth = window.screen.width;
  const viewPortHeight = window.innerHeight;
  const viewPortWidth = window.innerWidth;
  const pixelRatio = window.devicePixelRatio;

  return {
    screenHeight: screenHeight,
    screenWidth: screenWidth,
    viewPortHeight: viewPortHeight,
    viewPortWidth: viewPortWidth,
    pixelRatio: pixelRatio
  };
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updateCart: this.updateCart,
      cartCount: 0,
      cartTotalSum: 0.0,
      viewer: this.props.viewer,
      menuVisible: false,
      updateMenuVisible: this.updateMenuVisible,
      pageIndex: 'home',
      updatePageIndex: this.updatePageIndex,
      navFixed: false,
      updateNavFixed: this.updateNavFixed,
      loginName: false,
      updateLogin: this.updateLogin,
      imagesLoaded: true,
      updateImagesLoaded: this.updateImagesLoaded,
      shopFilter: 'all',
      updateShopFilter: this.updateShopFilter,
      updateViewMenu: this.updateViewMenu,
      viewMenu: false,
      updateViewCart: this.updateViewCart,
      viewCart: false,
      changeTheme: this.changeTheme,
      theme: 'bright'
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.viewMenu !== this.state.viewMenu) {
      return false;
    } else if (nextState.viewCart !== this.state.viewCart) {
      return false;
    } else {
      return true;
    }
  }

  changeTheme = theme => {
    this.setState({
      theme: theme
    });
  };

  updateCart = (cartCount, cartTotalSum) => {
    this.setState({
      cartCount,
      cartTotalSum
    });
  };

  updateViewMenu = state => {
    this.setState({
      viewMenu: state
    });
  };

  updateViewCart = state => {
    this.setState({
      viewCart: state
    });
  };

  updateMenuVisible = () => {
    this.setState(prevState => ({
      menuVisible: !prevState.menuVisible
    }));
  };

  updatePageIndex = newIndex => {
    this.setState({
      pageIndex: newIndex
    });
  };

  updateLogin = customerName => {
    this.setState({
      loginName: customerName
    });
  };

  updateImagesLoaded = newLoadedStatus => {
    this.setState({
      imagesLoaded: newLoadedStatus
    });
  };

  updateShopFilter = newShopFilter => {
    this.setState({
      shopFilter: newShopFilter
    });
  };

  render() {
    const vh = window.innerHeight * 0.01;
    const { theme, pageIndex } = this.state;
    // const { pageIndex } = this.state;

    return (
      <AppContext.Provider value={this.state}>
        <QueryRenderer
          environment={environment}
          query={graphql`
            query AppQuery($screen: ScreenType, $shopFilter: String) {
              viewer(screen: $screen) {
                ...HomeGrid_viewer
                ...About_viewer
                ...ShopItems_viewer @arguments(filter: $shopFilter)
                ...ShoppingCart_viewer
                ...Frame_viewer
                ...Menu_viewer
                ...Imprint_viewer
                ...Policies_viewer
              }
            }
          `}
          variables={{
            screen: screen(),
            shopFilter: 'bla'
          }}
          render={({ error, props }) => {
            if (error) {
              // console.log(error);
              return <div>Error!</div>;
            }
            if (!props) {
              return pageIndex === 'home' && <LoadingScreen />;
            }
            return (
              <div>
                <RelayContext.Provider value={props}>
                  <Helmet>
                    <style>
                      {`
                    :root {
                      --background-color: ${{ bright: '#f4f4f4', dark: '#4A4A4A', white: '#f4f4f4' }[theme]};
                      --text-color: ${{ bright: '#9c9c9c', dark: '#9c9c9c', white: 'white' }[theme]};
                      --logo-color: ${
                        { bright: '#9c9c9c', dark: '#9c9c9c', white: 'white' }[
                          !(pageIndex === 'productDetail') ? theme : 'white'
                        ]
                      };
                      }
                    `}
                    </style>
                    <title>VEJOME - Home</title>
                    <meta name="keywords" content="VEJOME, schmuck, shop, jewelry, passau" />
                    <meta name="author" content="Norscodes" />
                    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                    <link rel="manifest" href="/site.webmanifest" />
                    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#9c9c9c" />
                    <meta name="msapplication-TileColor" content="#f4f4f4" />
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                    />
                    {/* <script src="https://www.paypal.com/sdk/js?client-id=sb" data-version-4 data-log-level="info" /> */}
                    <script src="https://www.paypalobjects.com/api/checkout.js" />
                  </Helmet>
                  <div className={styles.app} style={{ '--vh': `${vh}px` }}>
                    <Frame>
                      <Switch>
                        <Route path="/shop" component={Shop} />
                        <RouteWithTheme theme="dark" path="/about" component={About} />
                        <RouteWithTheme theme="dark" path="/policies" component={Policies} />
                        <RouteWithTheme theme="dark" path="/imprint" component={Imprint} />
                        <RouteWithTheme theme="bright" path="/:id" component={Home} />
                        <RouteWithTheme theme="bright" path="/" component={Home} />
                      </Switch>
                    </Frame>
                  </div>
                </RelayContext.Provider>
              </div>
            );
          }}
        />
      </AppContext.Provider>
    );
  }
}

export default App;
