import React, { PureComponent } from 'react';
import styles from './index.module.css';
import onClickOutside from 'react-onclickoutside';
import MediaQuery from 'react-responsive';
import { Field } from 'formik';

import { ReactComponent as Dot } from './dot.svg';

import isHoverable from 'utilities/hover';

const hoverable = isHoverable();

class DropDownList extends PureComponent {
  state = {
    listOpen: false,
    headerTitle: this.props.title,
    hover: false
  };

  handleClickOutside = evt => {
    this.setState(prevState => ({
      listOpen: false
    }));
  };

  setHeaderTitle = value => {
    this.setState({
      headerTitle: value,
      headerColor: '#4A4A4A'
    });
  };

  toggleList = () => {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }));
  };

  mouseEnter = index => {
    this.setState({
      hover: {
        [index]: true
      }
    });
  };

  mouseLeave = index => {
    this.setState({
      hover: {
        [index]: false
      }
    });
  };

  handleSelect = e => {
    const { list, setOptionMaterialIndex } = this.props;
    const option = e.target.value; //JSON.parse(e.target.bla)
    const item = list.filter(item => {
      return item.node.attributeValues.edges[0].node.attributeOption.option === option;
    });
    const productID = item[0].node.id;
    const attributeName = item[0].node.attributeValues.edges[0].node.attribute.name;
    const optionIndex = e.target.selectedIndex - 1;
    if (attributeName.toLowerCase() === 'material') {
      setOptionMaterialIndex(optionIndex);
      this.props.setFieldValue('imageIndex', optionIndex);
    }
    this.props.handleChange(e);
    this.props.selectProductType('productID', productID);
    this.props.selectProductType(attributeName.toLowerCase(), option);
    this.setHeaderTitle(option);
    this.props.setFieldValue(attributeName.toLowerCase(), option);
    this.props.setFieldValue('productID', productID);
  };

  render() {
    const { list, selectProductType, setOptionMaterialIndex } = this.props;
    const { listOpen, headerTitle, hover } = this.state;

    let filterList = [];

    return (
      <div className={styles.main}>
        {listOpen && (
          <div>
            <MediaQuery minWidth={1024}>
              <Field component="ul" className={styles.ulList}>
                {list
                  .filter(item => {
                    const option = item.node.attributeValues.edges[0].node.attributeOption.option;
                    if (filterList.includes(option)) {
                      return false;
                    } else {
                      filterList.push(option);
                      return true;
                    }
                  })
                  .map((item, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        const option = item.node.attributeValues.edges[0].node.attributeOption.option;
                        const productID = item.node.id;
                        const attributeName = item.node.attributeValues.edges[0].node.attribute.name;
                        const optionIndex = index;
                        if (attributeName.toLowerCase() === 'material') {
                          setOptionMaterialIndex(optionIndex);
                          this.props.setFieldValue('imageIndex', optionIndex);
                        }
                        selectProductType(attributeName.toLowerCase(), option);
                        selectProductType('productID', productID);
                        this.toggleList();
                        this.setHeaderTitle(option);
                        this.props.setFieldValue(attributeName.toLowerCase(), option);
                        this.props.setFieldValue('productID', productID);
                      }}
                      onMouseEnter={() => (hoverable ? this.mouseEnter(index) : undefined)}
                      onMouseLeave={() => (hoverable ? this.mouseLeave(index) : undefined)}
                      className={styles.ulListItem}
                    >
                      {hover[index] && (
                        <span className={styles.dot}>
                          <Dot className={styles.deepDot}/>
                        </span>
                      )}
                      <span>{item.node.attributeValues.edges[0].node.attributeOption.option.toUpperCase()}</span>
                    </li>
                  ))}
              </Field>
            </MediaQuery>
          </div>
        )}

        <MediaQuery maxWidth={1023}>
          <div className={styles.headerFrame}>
            <Field
              className={styles.select}
              component="select"
              placeholder={headerTitle}
              onChange={e => this.handleSelect(e)}
              name={list[0].node.attributeValues.edges[0].node.attribute.name.toLowerCase()}
            >
              <option value="" disabled selected>
                Select {headerTitle}
              </option>
              {list
                .filter(item => {
                  const option = item.node.attributeValues.edges[0].node.attributeOption.option;
                  if (filterList.includes(option)) {
                    return false;
                  } else {
                    filterList.push(option);
                    return true;
                  }
                })
                .map((item, index) => {
                  const option = item.node.attributeValues.edges[0].node.attributeOption.option;
                  return (
                    <option key={index} data-index={index} name={option} value={option}>
                      {option}
                    </option>
                  );
                })}
            </Field>
            <div className={styles.plus}>+</div>
          </div>
        </MediaQuery>

        <MediaQuery minWidth={1024}>
          <div className={styles.header} style={{ color: this.state.headerColor }} onClick={this.toggleList}>
            {headerTitle.toUpperCase()}
            {listOpen ? ' -' : ' +'}
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default onClickOutside(DropDownList);
