import React from 'react';
// import registerServiceWorker from './registerServiceWorker';
import { render } from 'react-dom';
import './index.css';
import App from 'containers/App';
import { BrowserRouter } from 'react-router-dom';
// import Cookies from 'universal-cookie';
// import verifyTokenMutation from 'VerifyTokenMutation'

// const cookies = new Cookies();

// function check_login() {
//   const token = cookies.get('AUTH_TOKEN')
//   const tokenVer = verifyTokenMutation(token, (token) => {
//     return true
//   })
// }

// function loadPolyfills() {
//   const polyfills = []
 
//   if (!supportsIntersectionObserver()) {
//     polyfills.push(import('intersection-observer'))
//   }
 
//   return Promise.all(polyfills)
// }
 
// function supportsIntersectionObserver() {
//   return (
//     'IntersectionObserver' in global &&
//     'IntersectionObserverEntry' in global &&
//     'intersectionRatio' in IntersectionObserverEntry.prototype
//   )
// }

(() => {
    render(
      <BrowserRouter> 
        <App />
      </BrowserRouter>
    ,
    document.getElementById('root')
    );
  })();

// registerServiceWorker();