/**
 * @flow
 * @relayHash 614019d7791f11153e1144d8b8accdbe
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateProductQuantityBasketInput = {
  productID: string,
  direction: string,
  clientMutationId?: ?string,
};
export type UpdateProductQuantityMutationVariables = {|
  input: UpdateProductQuantityBasketInput
|};
export type UpdateProductQuantityMutationResponse = {|
  +updateProductQuantity: ?{|
    +productID: ?string,
    +basket: ?{|
      +getTotalInclTax: ?string,
      +getTotalTax: ?string,
      +getTotalItems: ?string,
      +lines: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +product: {|
              +title: string
            |},
            +quantity: number,
          |}
        |}>
      |},
    |},
  |}
|};
export type UpdateProductQuantityMutation = {|
  variables: UpdateProductQuantityMutationVariables,
  response: UpdateProductQuantityMutationResponse,
|};
*/


/*
mutation UpdateProductQuantityMutation(
  $input: UpdateProductQuantityBasketInput!
) {
  updateProductQuantity(input: $input) {
    productID
    basket {
      getTotalInclTax
      getTotalTax
      getTotalItems
      lines {
        edges {
          node {
            product {
              title
              id
            }
            quantity
            id
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateProductQuantityBasketInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateProductQuantityBasketInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "productID",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "getTotalInclTax",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "getTotalTax",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "getTotalItems",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateProductQuantityMutation",
  "id": null,
  "text": "mutation UpdateProductQuantityMutation(\n  $input: UpdateProductQuantityBasketInput!\n) {\n  updateProductQuantity(input: $input) {\n    productID\n    basket {\n      getTotalInclTax\n      getTotalTax\n      getTotalItems\n      lines {\n        edges {\n          node {\n            product {\n              title\n              id\n            }\n            quantity\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateProductQuantityMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateProductQuantity",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateProductQuantityBasketPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "basket",
            "storageKey": null,
            "args": null,
            "concreteType": "BasketNode",
            "plural": false,
            "selections": [
              v3,
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "lines",
                "storageKey": null,
                "args": null,
                "concreteType": "LineNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LineNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LineNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "product",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProductNode",
                            "plural": false,
                            "selections": [
                              v6
                            ]
                          },
                          v7
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateProductQuantityMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateProductQuantity",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateProductQuantityBasketPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "basket",
            "storageKey": null,
            "args": null,
            "concreteType": "BasketNode",
            "plural": false,
            "selections": [
              v3,
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "lines",
                "storageKey": null,
                "args": null,
                "concreteType": "LineNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LineNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LineNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "product",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProductNode",
                            "plural": false,
                            "selections": [
                              v6,
                              v8
                            ]
                          },
                          v7,
                          v8
                        ]
                      }
                    ]
                  }
                ]
              },
              v8
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '30e7e8580b75bbc5b64940fe892f71c1';
module.exports = node;
