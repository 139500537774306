/**
 * @flow
 * @relayHash 47f29434293c84e5b73c9aa6c1046ddb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClearBasketInput = {
  paid?: ?boolean,
  clientMutationId?: ?string,
};
export type ClearBasketMutationVariables = {|
  input: ClearBasketInput
|};
export type ClearBasketMutationResponse = {|
  +clearBasket: ?{|
    +basket: ?{|
      +getTotalInclTax: ?string,
      +getTotalTax: ?string,
      +getTotalItems: ?string,
      +lines: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +product: {|
              +title: string
            |},
            +quantity: number,
          |}
        |}>
      |},
    |}
  |}
|};
export type ClearBasketMutation = {|
  variables: ClearBasketMutationVariables,
  response: ClearBasketMutationResponse,
|};
*/


/*
mutation ClearBasketMutation(
  $input: ClearBasketInput!
) {
  clearBasket(input: $input) {
    basket {
      getTotalInclTax
      getTotalTax
      getTotalItems
      lines {
        edges {
          node {
            product {
              title
              id
            }
            quantity
            id
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ClearBasketInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ClearBasketInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "getTotalInclTax",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "getTotalTax",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "getTotalItems",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ClearBasketMutation",
  "id": null,
  "text": "mutation ClearBasketMutation(\n  $input: ClearBasketInput!\n) {\n  clearBasket(input: $input) {\n    basket {\n      getTotalInclTax\n      getTotalTax\n      getTotalItems\n      lines {\n        edges {\n          node {\n            product {\n              title\n              id\n            }\n            quantity\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ClearBasketMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "clearBasket",
        "storageKey": null,
        "args": v1,
        "concreteType": "ClearBasketPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "basket",
            "storageKey": null,
            "args": null,
            "concreteType": "BasketNode",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "lines",
                "storageKey": null,
                "args": null,
                "concreteType": "LineNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LineNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LineNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "product",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProductNode",
                            "plural": false,
                            "selections": [
                              v5
                            ]
                          },
                          v6
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ClearBasketMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "clearBasket",
        "storageKey": null,
        "args": v1,
        "concreteType": "ClearBasketPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "basket",
            "storageKey": null,
            "args": null,
            "concreteType": "BasketNode",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "lines",
                "storageKey": null,
                "args": null,
                "concreteType": "LineNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LineNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LineNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "product",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProductNode",
                            "plural": false,
                            "selections": [
                              v5,
                              v7
                            ]
                          },
                          v6,
                          v7
                        ]
                      }
                    ]
                  }
                ]
              },
              v7
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '17164ce33ef25b59510f4df5b749efb0';
module.exports = node;
