import React, {Fragment} from 'react';
import { Route } from 'react-router-dom';

import {Helmet} from "react-helmet";

const RouteWithTheme = ({ component: Component, theme, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => (
        <Fragment>
				<Helmet>
          <style>
            {`
            :root {
              --background-color: ${{ bright: '#f4f4f4', dark: '#4A4A4A', white: '#f4f4f4' }[theme]};
              --text-color: ${{ bright: '#9c9c9c', dark: '#9c9c9c', white: 'white' }[theme]};
              --logo-color: ${
                { bright: '#9c9c9c', dark: '#9c9c9c', white: 'white'}[theme]
              };
              }
            `}
          </style>
          </Helmet>
					<Component {...props} />
        </Fragment>
			)}
		/>
	);
};

export default RouteWithTheme