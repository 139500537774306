import React, { useState, useEffect } from 'react';
import { createFragmentContainer } from 'react-relay';
import { withRelayContext, withPageIndex } from 'containers/App';
import { graphql } from 'babel-plugin-relay/macro';
import MediaQuery from 'react-responsive';
import styles from './index.module.css';

import ReactScrollWheelHandler from 'react-scroll-wheel-handler';
import { useTransition, animated, config } from 'react-spring';

import HomeLookBook from 'containers/HomeLookBook/';
import HomeGrid from 'containers/HomeGrid';
import LandingpageDescription from 'containers/LandingpageDescription';
import DarkenBackground from 'components_simple/DarkenBackground';

import {Helmet} from "react-helmet";

// const isNotTablet = () => {
//   const x = window.matchMedia('(min-width: 1024px)');

//   return x.matches;
// };

const Home = props => {
  const { id: homeID = 0 } = props.match && props.match.params;
  const homeIDInt = parseInt(homeID);
  const [viewDescription, setViewDescription] = useState({});
  const [viewLookbook, setViewLookbook] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [direction, setDirection] = useState('down');

  useEffect(() => {
    homeIDInt !== null && setPageIndex(homeIDInt);
  }, [homeIDInt]);

  useEffect(() => {
    props.updatePageIndex('home');
  }, []);

  // useEffect(() => {
  //   viewLookbook[pageIndex] === true ? props.changeTheme('white') : props.changeTheme('bright');
  //   // props.updatePageIndex('home');
  //   // return props.changeTheme('bright');
  // }, [viewLookbook]);

  const toggleLookbook = index => {
    setViewLookbook(prevstate => ({
      ...prevstate,
      [index]: !prevstate[index]
    }));
  };

  const toggleDescription = (index, action = 'open') => {
    if (action === 'close') {
      setViewDescription(state => ({
        ...state,
        [index]: false
      }));
    } else {
      setViewDescription(prevstate => ({
        ...prevstate.viewDescription,
        [index]: !prevstate[index]
      }));
    }
  };

  const { landingPage } = props.viewer;
  // CHANGE INDEX
  const nextIndex = () => {
    const landingpageLength = landingPage.length;

    if (pageIndex === landingpageLength - 1) {
      return;
    }

    direction !== 'down' && setDirection('down');

    return setPageIndex(state => state + 1);
  };

  const prevIndex = () => {
    if (pageIndex === 0) {
      return;
    }
    direction !== 'up' && setDirection('up');

    return setPageIndex(state => state - 1);
  };

  const transitions = useTransition(landingPage[pageIndex], item => item.id, {
    from: { transform: direction === 'down' ? 'translate3d(0, 100vh,0)' : 'translate3d(0, -100vh,0)', opacity: 0.5 },
    enter: { transform: direction === 'down' ? 'translate3d(0, 0vh,0)' : 'translate3d(0, 0vh,0)', opacity: 1 },
    leave: { transform: direction === 'down' ? 'translate3d(0, -100vh,0)' : 'translate3d(0, 100vh,0)', opacity: 0.5 },
    config: config.slow
  });

  return (
    <div className={styles.main}>
      <Helmet>
      <style>
          {`
          :root {
            --background-color: ${{ bright: '#f4f4f4', dark: '#4A4A4A', white: '#f4f4f4' }[viewLookbook[pageIndex] === true ? 'white' : 'bright']};
            --text-color: ${{ bright: '#9c9c9c', dark: '#9c9c9c', white: 'white' }[viewLookbook[pageIndex] === true ? 'white' : 'bright']};
            --logo-color: ${
              { bright: '#9c9c9c', dark: '#9c9c9c', white: 'white' }[viewLookbook[pageIndex] === true ? 'white' : 'bright']
            };
            }
          `}
        </style>
      </Helmet>
      <ReactScrollWheelHandler
        upHandler={() => prevIndex()}
        downHandler={() => nextIndex()}
        customStyle={{
          width: '100%',
          height: '100%'
        }}
      >
        <div className={styles.frameDesktop}>
          {transitions.map(({ item, props, key }) => {
            const index = pageIndex;

            return (
              <animated.div key={key} className={styles.body} style={{ ...props, background: '#f4f4f4' }}>
                <DarkenBackground in={viewDescription[index]} />
                <HomeGrid key={1} show={!viewLookbook[index]} grid={item} />
                <HomeLookBook key={2} show={viewLookbook[index]} lookbook={item} />

                <MediaQuery maxWidth={1023}>
                  <div
                    className={`${styles.lookbook} ignore-react-onclickoutside`}
                    onClick={() => toggleLookbook(index)}
                  >
                    LOOKBOOK {viewLookbook[index] ? '-' : '+'}
                  </div>

                  <div
                    className={`${styles.description} ignore-react-onclickoutside`}
                    onClick={() => toggleDescription(index)}
                  >
                    DESCRIPTION {viewDescription[index] ? '-' : '+'}
                  </div>
                </MediaQuery>
                <MediaQuery minWidth={1024}>
                  <div className={styles.horizontalBottom}>
                    <div
                      className={`${styles.lookbook} ignore-react-onclickoutside`}
                      onClick={() => toggleLookbook(index)}
                    >
                      LOOKBOOK {viewLookbook[index] ? '-' : '+'}
                    </div>
                    <div
                      className={`${styles.description} ignore-react-onclickoutside`}
                      onClick={() => toggleDescription(index)}
                    >
                      DESCRIPTION {viewDescription[index] ? '-' : '+'}
                    </div>
                  </div>
                </MediaQuery>
                <LandingpageDescription
                  eventTypes={['click']}
                  show={viewDescription[index]}
                  info={item}
                  toggleOpen={action => toggleDescription(index, action)}
                />
              </animated.div>
            );
          })}
        </div>
      </ReactScrollWheelHandler>
    </div>
  );
};

export default withPageIndex(
  withRelayContext(
    createFragmentContainer(
      Home,
      graphql`
        fragment HomeGrid_viewer on Viewer {
          landingPage {
            id
            ...HomeLookBook_lookbook
            ...HomeGrid_grid
            ...LandingpageDescription_info
          }
        }
      `
    )
  )
);

// export default Home;
