import React, { PureComponent } from 'react';
import { createFragmentContainer } from 'react-relay';
import { withRelayContext, withShopFilterContext } from 'containers/App';
import { graphql } from 'babel-plugin-relay/macro';
import onClickOutside from 'react-onclickoutside';
import styles from './index.module.css';

import AddHtml from 'components_simple/AddHtml';

const transitionStyles = {
  true: { transform: 'translateY(0)' },
  false: { transform: 'translateY(200vh)' }
};

class LandingpageDescription extends PureComponent {
  state = {
    selected: ''
  };

  handleClickOutside = evt => {
    this.props.toggleOpen('close');
  };

  selectItem = data => {
    data = data.toLowerCase();
    this.setState({
      selected: data
    });
    this.props.updateShopFilter(data);
    this.props.toggleOpen();
  };

  render() {
    const { name, description, year, subtext } = this.props.info;
    const { show } = this.props;

    return (
      <div
        className={styles.main}
        style={{
          ...transitionStyles[show]
        }}
      >
        <div className={styles.body}>
          <div className={styles.title}>{name}</div>
          <div className={styles.year}>({year})</div>
          <div className={styles.description}>
            <AddHtml html={description} />
          </div>
          <div className={styles.subtext}>{subtext}</div>
        </div>
      </div>
    );
  }
}

export default withShopFilterContext(
  withRelayContext(
    createFragmentContainer(
      onClickOutside(LandingpageDescription),
      graphql`
        fragment LandingpageDescription_info on RangeNode {
          name
          description
          year
          subtext
        }
      `
    )
  )
);
