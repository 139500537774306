import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { withRelayContext } from 'containers/App';
import { graphql } from 'babel-plugin-relay/macro';
import classNames from 'classnames/bind';

import styles from './index.module.css';
import Product from 'containers/Product';

const cx = classNames.bind(styles);

const HomeGrid = props => {
  let { edges } = props.grid.includedProducts;

  function compare(a, b) {
    if (a.node.landingpageImage.edges[0].node.order < b.node.landingpageImage.edges[0].node.order) return -1;
    if (a.node.landingpageImage.edges[0].node.order > b.node.landingpageImage.edges[0].node.order) return 1;
    return 0;
  }

  let sorted = [].concat(edges);
  sorted.sort(compare);
  return (
    <section>
      <div className={styles.main}>
        {sorted.map((edge, index) => {
          const itemClass = cx(`item${index + 1}`, 'gridItem');
          return (
            <div key={index} className={itemClass}>
              <Product key={edge.node.id} order={index + 1} product={edge.node} />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default withRelayContext(
  createFragmentContainer(
    HomeGrid,
    graphql`
      fragment HomeGrid_grid on RangeNode {
        name
        description
        includedProducts {
          edges {
            node {
              ...Product_product
              title
              landingpageImage {
                edges {
                  node {
                    original
                    order
                  }
                }
              }
            }
          }
        }
      }
    `
  )
);
