import React from 'react';
import styles from './index.module.css';

import AddHtml from 'components_simple/AddHtml';

const ShopDetailProductInformation = props => {
  const { title, description, extra } = props;
  return (
    <div className={styles.main}>
      <div className={styles.title}>{title.toUpperCase()}</div>
      <div className={styles.description}>
        <AddHtml html={description} />
      </div>
      <div className={styles.extra}>
        {extra.map((group, index) => (
          <div key={`${group.node.id} + ${index}`} className={styles.extraGroup}>
            <div className={styles.extraTitle}>{group.node.name}</div>
            <div>
              {group.node.optionGroup.options.edges.map((item, indexDeep) => (
                <div
                  key={`${item.node.id} + ${indexDeep}`}
                  style={{
                    textTransform: 'initial'
                  }}
                >
                  {item.node.description}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.fillItem}></div>
    </div>
  );
};

export default ShopDetailProductInformation;
