import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.css';

// import { ReactComponent as Dot } from './dot.svg';
import logo from './dot.svg';

import isHoverable from 'utilities/hover';

const hoverable = isHoverable();

class MenuItem extends PureComponent {
  state = {
    hover: false
  };

  mouseEnter = () => {
    this.setState({
      hover: true
    });
  };

  mouseLeave = () => {
    this.setState({
      hover: false
    });
  };

  render() {
    const { name, linkTo } = this.props;
    const { hover } = this.state;

    return (
      <Link to={linkTo}>
        <div
          className={styles.item}
          onMouseEnter={hoverable ? this.mouseEnter : undefined}
          onMouseLeave={hoverable ? this.mouseLeave : undefined}
        >
          {hover && (
            <span className={styles.dot}>
              <img src={logo} alt="dot" />
              {/* <Dot className={styles.realDot} /> */}
            </span>
          )}
          <span>{name}</span>
        </div>
      </Link>
    );
  }
}

export default MenuItem;
