import React, { PureComponent } from 'react';
import { createFragmentContainer } from 'react-relay';
import { withRelayContext, withShopFilterContext } from 'containers/App';
import { graphql } from 'babel-plugin-relay/macro';
import onClickOutside from 'react-onclickoutside';
import styles from './index.module.css';

import MenuItem from 'containers/MenuItem';

// import isHoverable from 'utilities/hover';

// const hoverable = isHoverable();

const transitionStyles = {
  true: { left: 0 },
  false: { left: '-88vw' }
};

class Menu extends PureComponent {
  state = {
    selected: '',
    date: new Date()
  };

  handleClickOutside = evt => {
    const { show } = this.props;
    show && this.props.toggleOpen('close');
  };

  selectItem = data => {
    data = data.toLowerCase();
    this.setState({
      selected: data
    });
    this.props.updateShopFilter(data);
    setTimeout(() => {
      this.props.toggleOpen();
    }, 10);
  };

  mouseEnter = () => {
    this.setState({
      hover: true
    });
  };

  mouseLeave = () => {
    this.setState({
      hover: false
    });
  };

  close = () => {
    const { show } = this.props;
    setTimeout(() => {
      show && this.props.toggleOpen();
    }, 10);
  };

  render() {
    const { items, collections } = this.props.viewer;
    const { show } = this.props;
    const { date } = this.state;

    return (
      <div
        className={styles.main}
        style={{
          ...transitionStyles[show]
        }}
      >
        <div className={styles.frame}>
          <div className={styles.title}>COLLECTIONS</div>
          <div className={styles.itemsCollections}>
            {collections &&
              collections.map((item, index) => (
                <span onClick={() => this.selectItem(item.name)} key={index}>
                  <MenuItem name={item.name} linkTo={`/${index}`} />
                </span>
              ))}
          </div>
        </div>
        <div className={styles.frame}>
          <div className={styles.title}>ITEMS</div>
          <div className={styles.items}>
            {items &&
              items.map((item, index) => (
                <span onClick={() => this.selectItem(item.name)} key={index}>
                  <MenuItem name={item.name} linkTo="/shop" />
                </span>
              ))}
          </div>
        </div>
        <div className={styles.frame}>
          <div className={styles.title}>INFO</div>
          <div className={styles.items} onClick={this.close}>
            <MenuItem name="CONTACT" linkTo="/about" />
            <MenuItem name="POLICIES" linkTo="/policies" />
            <MenuItem name="IMPRINT" linkTo="/imprint" />
          </div>
        </div>
        <div className={styles.copyright}>
          <div>COPYRIGHT: VEJOME, {date.toLocaleDateString('en-US', { year: 'numeric' })}, ALL RIGHTS RESERVED</div>
        </div>
      </div>
    );
  }
}

export default withShopFilterContext(
  withRelayContext(
    createFragmentContainer(
      onClickOutside(Menu),
      graphql`
        fragment Menu_viewer on Viewer {
          collections: landingPage {
            id
            name
            slug
          }
          items: categoriesShop(node: "items") {
            name
          }
        }
      `
    )
  )
);
