import {
  commitMutation,
} from 'react-relay';
import environment from 'environment';
import { graphql } from 'babel-plugin-relay/macro'

const mutation = graphql`
mutation AddProductToBasketMutation(
  $input: AddProductToBasketInput!,
) {
  addProductToBasket(
    input: $input
  ) {
    productID
    basket {
      getTotalInclTax
      getTotalTax
      getTotalItems
      lines {
        edges {
          node {
            imageIndex
            product {
              id
              attributeValues {
                edges {
                  node {
                    attributeOption {
                      
                      option
                    }
                    valueMultiOption {
                      edges {
                        node {
                          option
                          group {
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
              parent {
                title
                images {
                  edges {
                    node {
                      original
                    }
                  }
                }
              }
            }
            priceExclTax
            priceInclTax
            quantity
            priceCurrency
          }
        }
      }
    }
  }
}
`;


export default ( 
  form,
) => {
  const variables = {
    input: form,
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        // console.log(response)
      },
      onError: err => console.error(err),
    },
  );
}