import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { withRelayContext } from 'containers/App';
import { graphql } from 'babel-plugin-relay/macro';
import styles from './index.module.css';
// import { Transition } from 'react-transition-group';

import ImageCarousel from 'containers/ImageCarousel';

const isTablet = x => {
  return x.matches;
};

var x = window.matchMedia('(max-width: 1023px)');

x.addListener(isTablet);

const transitionStyles = {
  true: { transform: 'translateY(0)' },
  false: { transform: 'translateY(200vh)' }
};

const HomeLookBook = props => {
  const { edges: images } = props.lookbook.landingpageLookbookImages;
  const { edges: imagesMobile } = props.lookbook.landingpageLookbookImagesMobile;

  const tablet = isTablet(x);

  const { show } = props;
  return (
    <div className={styles.main} style={{ ...transitionStyles[show] }}>
      <ImageCarousel splitClick={true} images={tablet ? imagesMobile : images} />
    </div>
  );
};

HomeLookBook.defaultProps = {
  show: false
};

export default withRelayContext(
  createFragmentContainer(
    HomeLookBook,
    graphql`
      fragment HomeLookBook_lookbook on RangeNode {
        landingpageLookbookImages {
          edges {
            node {
              original
              id
            }
          }
        }
        landingpageLookbookImagesMobile {
          edges {
            node {
              original
              id
            }
          }
        }
      }
    `
  )
);
