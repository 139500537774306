/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ShoppingCart_viewer$ref: FragmentReference;
export type ShoppingCart_viewer = {|
  +basket: ?{|
    +getTotalInclTax: ?string,
    +getTotalTax: ?string,
    +lines: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +imageIndex: number,
          +product: {|
            +id: string,
            +title: string,
            +upc: ?string,
            +attributeValues: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +attributeOption: ?{|
                    +option: string
                  |},
                  +valueMultiOption: ?{|
                    +edges: $ReadOnlyArray<?{|
                      +node: ?{|
                        +option: string,
                        +group: {|
                          +name: string
                        |},
                      |}
                    |}>
                  |},
                |}
              |}>
            |},
            +parent: ?{|
              +slug: string,
              +title: string,
              +images: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +original: string
                  |}
                |}>
              |},
            |},
          |},
          +quantity: number,
          +priceExclTax: ?number,
          +priceInclTax: ?number,
          +priceCurrency: string,
        |}
      |}>
    |},
  |},
  +$refType: ShoppingCart_viewer$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "option",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ShoppingCart_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "basket",
      "storageKey": null,
      "args": null,
      "concreteType": "BasketNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "getTotalInclTax",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "getTotalTax",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "lines",
          "storageKey": null,
          "args": null,
          "concreteType": "LineNodeConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "LineNodeEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "LineNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "imageIndex",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "product",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ProductNode",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "id",
                          "args": null,
                          "storageKey": null
                        },
                        v0,
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "upc",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "attributeValues",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ProductAttributeValueNodeConnection",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "edges",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "ProductAttributeValueNodeEdge",
                              "plural": true,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "node",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "ProductAttributeValueNode",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "attributeOption",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "AttributeOptionNode",
                                      "plural": false,
                                      "selections": [
                                        v1
                                      ]
                                    },
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "valueMultiOption",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "AttributeOptionNodeConnection",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "edges",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "AttributeOptionNodeEdge",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "kind": "LinkedField",
                                              "alias": null,
                                              "name": "node",
                                              "storageKey": null,
                                              "args": null,
                                              "concreteType": "AttributeOptionNode",
                                              "plural": false,
                                              "selections": [
                                                v1,
                                                {
                                                  "kind": "LinkedField",
                                                  "alias": null,
                                                  "name": "group",
                                                  "storageKey": null,
                                                  "args": null,
                                                  "concreteType": "AttributeOptionGroupNode",
                                                  "plural": false,
                                                  "selections": [
                                                    {
                                                      "kind": "ScalarField",
                                                      "alias": null,
                                                      "name": "name",
                                                      "args": null,
                                                      "storageKey": null
                                                    }
                                                  ]
                                                }
                                              ]
                                            }
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "parent",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ProductNode",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "slug",
                              "args": null,
                              "storageKey": null
                            },
                            v0,
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "images",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "ProductImageNodeConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "ProductImageNodeEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "ProductImageNode",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "ScalarField",
                                          "alias": null,
                                          "name": "original",
                                          "args": null,
                                          "storageKey": null
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "quantity",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "priceExclTax",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "priceInclTax",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "priceCurrency",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '123767bf69fad4ed0320dd3779ff16aa';
module.exports = node;
