import React, { PureComponent } from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import styles from './index.module.css';

import Image from 'components_simple/Image';

class ShopItemsProduct extends PureComponent {
  state = {
    hover: false
  };

  mouseEnter = () => {
    this.setState({ hover: true });
  };

  mouseLeave = () => {
    this.setState({ hover: false });
  };

  render() {
    const { title, slug, images, children } = this.props.product;
    const { original } = images.edges[0].node;

    let priceExclTax = 0;
    if (children.edges.length >= 1) {
      priceExclTax = children.edges[0].node.productInStock.priceExclTax;
    }

    const material = children.edges.length > 1 ? 'Various materials' : 'One material';

    const { hover } = this.state;

    return (
      <div className={styles.main} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
        <Link to={`/shop/${slug}`}>
          <Image url={original} />
          <MediaQuery maxWidth={1023}>
            <div className={styles.information}>
              <div>{title.toUpperCase()}</div>
              <div style={{ fontSize: '0.5em' }}>{(priceExclTax * 1.19).toFixed(2)} €</div>
              <div style={{ fontSize: '0.5em' }}>{material.toUpperCase()}</div>
            </div>
          </MediaQuery>
          <MediaQuery minWidth={1024}>
            {hover && (
              <div className={styles.information}>
                <div style={{ paddingBottom: '0.2vw', fontSize: '1rem' }}>{title.toUpperCase()}</div>
                <div style={{ paddingBottom: '0.2vw', fontSize: '0.7rem' }}>{(priceExclTax * 1.19).toFixed(2)} €</div>
                <div style={{ fontSize: '0.7rem' }}>{material.toUpperCase()}</div>
              </div>
            )}
          </MediaQuery>
        </Link>
      </div>
    );
  }
}

export default createFragmentContainer(
  ShopItemsProduct,
  graphql`
    fragment ShopItemsProduct_product on ProductNode {
      title
      slug
      images {
        edges {
          node {
            original
          }
        }
      }
      children {
        edges {
          node {
            productInStock {
              priceExclTax
            }
          }
        }
      }
    }
  `
);
