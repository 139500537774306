import {
  commitMutation,
} from 'react-relay';
import environment from 'environment';
import { graphql } from 'babel-plugin-relay/macro'

const mutation = graphql`
mutation ClearBasketMutation(
  $input: ClearBasketInput!,
) {
  clearBasket(
    input: $input
  ) {
    basket {
      getTotalInclTax
      getTotalTax
      getTotalItems
      lines {
        edges {
          node {
            product {
							title
						}
						quantity
          }
        }
      }
    }
  }
}
`;

export default ( 
  paid,
) => {
  const variables = {
    input: {
      paid,
    },
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        // console.log(response)
      },
      onError: err => console.error(err),
    },
  );
}