import React, { PureComponent } from 'react'
import styles from './index.module.css'
import { withLoadingContext } from 'containers/App'

class Image extends PureComponent {
  state = {
    fit: this.props.fit
  }

  render () {
    const { url, fit, position, width, height } = this.props

    return (
      <img src={`${url}`}  className={styles.image} style={{height: `${height}`, width: `${width}`, objectFit:`${fit}`, objectPosition: `${position}`}} alt={url}/>
    )
  }
}

Image.defaultProps = {
  fit: "contain",
  position: "",
  width: "100%",
  height: "100%"
}

export default withLoadingContext(Image)