/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Frame_viewer$ref: FragmentReference;
export type Frame_viewer = {|
  +items: ?$ReadOnlyArray<?{|
    +name: string
  |}>,
  +basket: ?{|
    +getTotalItems: ?string
  |},
  +$refType: Frame_viewer$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "Frame_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "items",
      "name": "categoriesShop",
      "storageKey": "categoriesShop(node:\"items\")",
      "args": [
        {
          "kind": "Literal",
          "name": "node",
          "value": "items",
          "type": "String"
        }
      ],
      "concreteType": "CategoryNode",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "basket",
      "storageKey": null,
      "args": null,
      "concreteType": "BasketNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "getTotalItems",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '9ab0dc4331049328b9653d7817cd4554';
module.exports = node;
