import React, { PureComponent } from 'react';
import { createFragmentContainer } from 'react-relay';
import { withRelayContext } from 'containers/App';
import { graphql } from 'babel-plugin-relay/macro';
import { Link } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import styles from './index.module.css';
import MediaQuery from 'react-responsive';

import Image from 'components_simple/Image';
import UpdateProductQuantityMutation from 'mutations/UpdateProductQuantityMutation';
import ClearBasketMutation from 'mutations/ClearBasketMutation';
import PaypalButton from 'components/PaypalButton';
// import FullscreenInformation from 'components_simple/FullscreenInformation';

const transitionStyles = {
  true: { right: 0 },
  false: { right: 'calc(-80vw - (var(--frame-width) * 2 ) )' }
};

export const CLIENT = {
  sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX,
  production: 'ASggg-yUJH-dl4fb0raGrVK3TPXr3iieMFDxmd2EA2_UI888Dn767_k__K-MRvXtVrYPc6w6x0B6ZU0H'
};

const ENV = process.env.NODE_ENV === 'production' ? 'production' : 'sandbox';

class ShoppingCart extends PureComponent {
  state = {
    cancel: false,
    paid: false
  };

  handleClickOutside = evt => {
    const { show } = this.props;
    show && this.props.toggleOpen('close');
  };

  updateProductQuantity = (id, type, quantity) => {
    if ((quantity > 0 && type === 'remove') || type === 'add') {
      UpdateProductQuantityMutation(id, type);
    }
  };

  onSuccess = payment => {
    const paid = payment.paid;
    this.setState({
      paid: true
    });
    ClearBasketMutation(paid);
  };

  onError = error => {
    // console.log('Erroneous payment OR failed to load script!', error);
    this.setState({
      cancel: true
    });
  };

  onCancel = data => {
    this.setState({
      cancel: true
    });
  };

  continueShopping = () => {
    this.setState({ paid: false });
  };

  close = () => {
    this.props.toggleOpen();
  };

  render() {
    const { paid, cancel } = this.state;
    const { edges } = this.props.viewer.basket.lines;
    const empty = edges.length > 0 ? false : true;
    const { getTotalInclTax, getTotalTax } = this.props.viewer.basket;
    const { show } = this.props;

    return (
      <div
        className={styles.main}
        style={{
          ...transitionStyles[show]
        }}
      >
        {paid && (
          <div className={styles.paid} style={{ height: '100%', width: '100%' }} close={() => this.continueShopping()}>
            <div className={styles.paypalSuccess}>
              Thank you for your order. You will shortly receive an email from paypal with your order confirmation and
              details.
            </div>
            <Link to="/shop">
              <div className={styles.continueShopping} onClick={this.continueShopping}>
                CONTINUE SHOPPING
              </div>
            </Link>
          </div>
        )}
        {empty && (
          <div className={styles.emptyCart}>
            <div className={styles.emptyFrame}>
              <div className={styles.emptyCartInfo}>YOUR CART IS EMPTY</div>
              <div className={styles.goShopping} onClick={this.close}>
                <Link to="/shop">
                  <div>
                    <div className={styles.goShoppingText}>RETURN TO SHOP</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )}
        {!empty && (
          <div className={styles.body}>
            <div className={styles.listFrame}>
              <div className={styles.list}>
                <MediaQuery minWidth={1024}>
                  <div className={styles.titles}>
                    <div>ITEM</div>
                    <div />
                    <div className={styles.price}>PRICE</div>
                    <div className={styles.quantity}>QUANTITY</div>
                    <div className={styles.total}>TOTAL</div>
                  </div>
                </MediaQuery>
                {edges.length > 0 &&
                  edges.map((item, index) => {
                    const { original } = item.node.product.parent.images.edges[item.node.imageIndex].node;
                    const { slug } = item.node.product.parent;
                    const { edges } = item.node.product.attributeValues;
                    const { id } = item.node.product;
                    const { quantity } = item.node;
                    return (
                      <div key={index} className={styles.line}>
                        <div className={styles.lineImage}>
                          <Link key={id} to={`/shop/${slug}`}>
                            <Image url={original} position="left" />
                          </Link>
                        </div>
                        <MediaQuery maxWidth={1023}>
                          <div className={styles.box}>
                            <div style={{ justifySelf: 'start' }} className={styles.lineBox}>
                              <Link key={id} to={`/shop/${slug}`}>
                                <div className={styles.title}>{item.node.product.parent.title}</div>
                                <div className={styles.category} style={{ color: '#9C9C9C' }}>
                                  (
                                  {edges.map((item, index) => {
                                    const { option } = item.node.attributeOption;
                                    let text = '';
                                    if (index === 1) {
                                      text = ', ' + option;
                                    } else {
                                      text = option;
                                    }
                                    return <span key={index}>{text}</span>;
                                  })}
                                  )
                                </div>
                              </Link>
                            </div>
                            <div className={styles.innerBox}>
                              <div style={{ justifySelf: 'start' }} className={styles.lineBox}>
                                {item.node.priceInclTax.toFixed(2)} €
                              </div>
                              <div className={styles.lineBox}>
                                <span
                                  className={styles.remove}
                                  onClick={() => this.updateProductQuantity(id, 'remove', quantity)}
                                >
                                  -
                                </span>
                                {quantity}
                                <span
                                  className={styles.add}
                                  onClick={() => this.updateProductQuantity(id, 'add', quantity)}
                                >
                                  +
                                </span>
                              </div>
                              <div className={styles.lineBoxTotal}>
                                {(item.node.priceInclTax * item.node.quantity).toFixed(2)} €
                              </div>
                            </div>
                          </div>
                          <div className={styles.sepLine} />
                        </MediaQuery>
                        <MediaQuery minWidth={1024}>
                          <div style={{ justifySelf: 'start' }} className={`${styles.lineBox} ${styles.titleBox}`}>
                            <Link key={id} to={`/shop/${slug}`}>
                              <div className={styles.title}>{item.node.product.parent.title}</div>
                              <div style={{ color: '#9C9C9C' }}>
                                (
                                {edges.map((item, index) => {
                                  const { option } = item.node.attributeOption;
                                  let text = '';
                                  if (index === 1) {
                                    text = ', ' + option;
                                  } else {
                                    text = option;
                                  }
                                  return <span key={index}>{text}</span>;
                                })}
                                )
                              </div>
                            </Link>
                          </div>
                          <div className={styles.lineBox}>{item.node.priceInclTax.toFixed(2)} €</div>
                          <div className={styles.lineBox}>
                            <span
                              className={styles.remove}
                              onClick={() => this.updateProductQuantity(id, 'remove', quantity)}
                            >
                              -
                            </span>
                            {quantity}
                            <span
                              className={styles.add}
                              onClick={() => this.updateProductQuantity(id, 'add', quantity)}
                            >
                              +
                            </span>
                          </div>
                          <div className={styles.lineBoxTotal}>
                            {(item.node.priceInclTax * item.node.quantity).toFixed(2)} €
                          </div>
                          <div className={styles.sepLine} />
                        </MediaQuery>
                      </div>
                    );
                  })}
                <div className={styles.subTotalFrame}>
                  <div className={styles.subTotalTitle}>
                    <div>SUBTOTAL</div>
                    <MediaQuery maxWidth={1023}>
                      <div style={{ color: '#9C9C9C', fontSize: '1.7rem' }}>(INCL. VAT 19%) </div>
                    </MediaQuery>
                    <MediaQuery minWidth={1024}>
                      <div style={{ color: '#9C9C9C', fontSize: '0.7rem' }}>(INCL. VAT 19%) </div>
                    </MediaQuery>
                  </div>
                  <div className={styles.subTotal}>
                    <div>{getTotalInclTax} €</div>
                    <MediaQuery maxWidth={1023}>
                      <div style={{ color: '#9C9C9C', fontSize: '1.7rem' }}>({getTotalTax} €)</div>
                    </MediaQuery>
                    <MediaQuery minWidth={1024}>
                      <div style={{ color: '#9C9C9C', fontSize: '0.7rem' }}>({getTotalTax} €)</div>
                    </MediaQuery>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.paypalInformation}>
              {cancel && (
                <div>
                  <div>Sadly your payment was cancelled.</div>
                  <div>Please try again.</div>
                </div>
              )}
            </div>
            <div className={styles.checkout}>
              <div className={styles.shippingTitle}>Shipping costs are calculated at checkout</div>
              <div className={styles.checkoutTitle}>SUBTOTAL {getTotalInclTax} €</div>
              <span className={styles.checkoutButton}>
                <PaypalButton
                  client={CLIENT}
                  env={ENV}
                  commit={true}
                  currency={'EUR'}
                  total={100}
                  onSuccess={this.onSuccess}
                  onError={this.onError}
                  onCancel={this.onCancel}
                  information={this.props.viewer.basket}
                />
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRelayContext(
  createFragmentContainer(
    onClickOutside(ShoppingCart),
    graphql`
      fragment ShoppingCart_viewer on Viewer {
        basket {
          getTotalInclTax
          getTotalTax
          lines {
            edges {
              node {
                imageIndex
                product {
                  id
                  title
                  upc
                  attributeValues {
                    edges {
                      node {
                        attributeOption {
                          option
                        }
                        valueMultiOption {
                          edges {
                            node {
                              option
                              group {
                                name
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  parent {
                    slug
                    title
                    images {
                      edges {
                        node {
                          original
                        }
                      }
                    }
                  }
                }
                quantity
                priceExclTax
                priceInclTax
                priceCurrency
              }
            }
          }
        }
      }
    `
  )
);
