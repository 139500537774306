import React, { PureComponent } from 'react';
import styles from './index.module.css';
import { createFragmentContainer } from 'react-relay';
import { withRelayContext, withPageIndex } from 'containers/App';
import { graphql } from 'babel-plugin-relay/macro';
import AddHtml from 'components_simple/AddHtml';
import { Helmet } from 'react-helmet';

class Policies extends PureComponent {
  state = {
    categoryIndex: 0
  };

  componentDidMount() {
    // this.props.changeTheme('dark');
    this.props.updatePageIndex('empty');
  }

  // componentWillUnmount() {
  //   this.props.changeTheme('bright');
  // }

  changeCategory = index => {
    this.setState({
      categoryIndex: index
    });
  };

  render() {
    const { categoriesShop } = this.props.viewer;
    const { categoryIndex } = this.state;

    return (
      <div className={styles.main}>
        <Helmet>
          <title>VEJOME - Policies</title>
        </Helmet>
        <div className={styles.header}>
          {categoriesShop.map((item, index) => {
            const styleSelected = categoryIndex === index ? '#ffffff' : 'inherit';
            return (
              <span className={styles.headerItemBlock} key={index}>
                <span
                  style={{ color: `${styleSelected}` }}
                  className={styles.headerItem}
                  onClick={() => this.changeCategory(index)}
                >
                  {item.name}
                </span>
              </span>
            );
          })}
        </div>
        <div className={styles.body}>
          <div>
            <AddHtml html={categoriesShop[categoryIndex].description} />
          </div>
        </div>
      </div>
    );
  }
}

export default withPageIndex(
  withRelayContext(
    createFragmentContainer(
      Policies,
      graphql`
        fragment Policies_viewer on Viewer {
          categoriesShop(node: "Policies & services") {
            name
            description
          }
        }
      `
    )
  )
);
