
import {
  Environment,
  RecordSource,
  Store,
  Network
} from 'relay-runtime';
import RelayQueryResponseCache from 'relay-runtime/lib/RelayQueryResponseCache'
import Cookies from 'universal-cookie';

const oneHour = 60 * 60 * 1000
const cache = new RelayQueryResponseCache({ size: 250, ttl: oneHour })
const cookies = new Cookies();

// import {
//   RelayNetworkLayer,
//   urlMiddleware,
//   batchMiddleware,
//   loggerMiddleware,
//   errorMiddleware,
//   perfMiddleware,
//   retryMiddleware,
//   authMiddleware,
//   cacheMiddleware,
//   progressMiddleware,
// } from 'react-relay-network-modern';

function fetchQuery(
  operation,
  variables,
  cacheConfig,
) {
  const queryID = operation.text
  const isMutation =  operation.operationKind === 'Mutation'
  const isQuery = operation.operationKind === 'query'
  const forceFetch = cacheConfig && cacheConfig.forceFetch

  const fromCache = cache.get(queryID, variables)
  if (
    isQuery &&
    fromCache !== null &&
    !forceFetch
  ) {
    return fromCache
  }

  // function getToken() {
  //   // const token = store.get('jwt')
  //   const token = cookies.get('AUTH_TOKEN')
  //   const prefix = 'JWT '

  //   return prefix + token
  // }

  return fetch('/graphql', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
      credentials: 'include',
      'X_CSRFTOKEN': cookies.get('csrftoken'),
      // authorization: getToken()
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  }).then(response => {
    
    return response.json()
  }).then(json => {
    if (isQuery && json) {
      cache.set(queryID, variables, json)
    }

    if (isMutation) {
      cache.clear()
    }

    return json
  })
}

const store = new Store(new RecordSource())
const network = Network.create(fetchQuery) // new RelayNetworkLayer(
//   [
//     cacheMiddleware({
//       size: 100, // max 100 requests
//       ttl: 900000, // 15 minutes
//     }),
//     urlMiddleware({
//       url: req => Promise.resolve('/graphql'),
//     }),
//     batchMiddleware({
//       batchUrl: requestMap => Promise.resolve('/graphql/batch'),
//       batchTimeout: 10,
//     }),
//     authMiddleware({
//       token: () => cookies.get('AUTH_TOKEN'),
//       // tokenRefreshPromise: req => {
//       //   console.log('[client.js] resolve token refresh', req);
//       //   return fetch('/jwt/refresh')
//       //     .then(res => res.json())
//       //     .then(json => {
//       //       const token = json.token;
//       //       store.set('jwt', token);
//       //       return token;
//       //     })
//       //     .catch(err => console.log('[client.js] ERROR can not refresh token', err));
//       //  },
//       prefix: 'JWT ',
//       allowEmptyToken: true,
//     }),
//     progressMiddleware({
//       onProgress: (current, total) => {
//         console.log('Downloaded: ' + current + ' B, total: ' + total + ' B');
//       },
//     }),

//     // example of the custom inline middleware
//     next => async req => {
//       req.fetchOpts.headers['X-CSRFToken'] = window.csrf;
//       req.fetchOpts.credentials = 'same-origin'; // 
//       console.log('RelayRequest', req);
//       const res = await next(req);
//       console.log('RelayResponse', res);
//       return res;
//     },
//   ],
// ); 

const environment = new Environment({
  network,
  store,
});

export default environment;
