import React from 'react';
import ReactDOM from 'react-dom';

import paypal from 'paypal-checkout';

const PaypalBut = paypal.Button.driver('react', { React, ReactDOM });

const PaypalButton = props => {
  const { env, commit, client, onSuccess, onError, onCancel, information } = props;
  const { getTotalInclTax, getTotalTax, lines } = information;
  const totalAmount = getTotalInclTax;
  const totalTax = getTotalTax;
  const totalExcl = totalAmount - totalTax;

  const payment = (data, actions) => {
    let items = [];
    lines.edges.forEach(line => {
      const { priceCurrency, priceInclTax, priceExclTax, quantity, product } = line.node;
      const tax = (priceInclTax - priceExclTax).toFixed(2);
      const price = priceExclTax.toFixed(2);
      items.push({
        name: product.title,
        description: product.title,
        quantity: quantity,
        price: price,
        tax: tax,
        sku: product.upc,
        currency: priceCurrency
      });
    });
    return actions.payment.create({
      intent: 'sale',
      payer: {
        payment_method: 'paypal'
      },
      transactions: [
        {
          amount: {
            total: totalAmount,
            currency: 'EUR',
            details: {
              subtotal: totalExcl.toFixed(2),
              tax: totalTax
            }
          },
          description: 'VEJOME',
          item_list: {
            items: items
          }
        }
      ]
    });
    // return actions.order.create({
    //   purchase_units: [
    //     {
    //       amount: {
    //         value: '0.01'
    //       }
    //     }
    //   ]
    // });
  };

  const onAuthorize = (data, actions) =>
    actions.order.capture().then(() => {
      const payment = {
        paid: true,
        cancelled: false,
        payerID: data.payerID,
        paymentID: data.paymentID,
        paymentToken: data.paymentToken,
        returnUrl: data.returnUrl
      };
      onSuccess(payment);
    });

  return (
    <PaypalBut
      // createOrder={(data, actions) => payment(data, actions)}
      // onApprove={(data, actions) => onAuthorize(data, actions)}
      env={env}
      client={client}
      commit={commit}
      payment={payment}
      onAuthorize={onAuthorize}
      onCancel={onCancel}
      onError={onError}
    />
  );
};

// export default scriptLoader('https://www.paypalobjects.com/api/checkout.js')(PaypalButton);
export default PaypalButton;
