import React from 'react';
import styles from './index.module.css';
// import FullscreenInformation from 'components_simple/FullscreenInformation';
import { ReactComponent as Logo } from 'containers/Frame/logo_vejome.svg';
// import logo from 'containers/Frame/logo_vejome.svg';
import { withPageIndex } from 'containers/App';

const LoadingScreen = ({ text, pageIndex }) => {
  return (
    <div className={styles.main}>
      <div className={styles.vejome}>VEJOME</div>
      <div className={styles.text}>
        <div className={styles.logo}>
          <Logo fill="#9c9c9c" className={styles.realLogo} />
          {/* <img src={logo} alt="logo" className={styles.realLogo} /> */}
        </div>
      </div>
      <div className={styles.jewelry}>JEWELLERY</div>
    </div>
  );
};

export default withPageIndex(LoadingScreen);
