import {
  commitMutation,
} from 'react-relay';
import environment from 'environment';
import { graphql } from 'babel-plugin-relay/macro'

const mutation = graphql`
mutation UpdateProductQuantityMutation(
  $input: UpdateProductQuantityBasketInput!,
) {
  updateProductQuantity(
    input: $input
  ) {
    productID
    basket {
      getTotalInclTax
      getTotalTax
      getTotalItems
      lines {
        edges {
          node {
            product {
							title
						}
						quantity
          }
        }
      }
    }
  }
}
`;

// function sharedUpdater(store, viewerId, newEdge) {
//   const proxy = store.get(viewerId)

//   const cartItems = ConnectionHandler.getConnection(
//     proxy,
//     'CartItems_shoppingCartItems'
//   )

//   const newItem = ConnectionHandler.createEdge(store, cartItems, newEdge, 'test')

//   const edges = cartItems.getLinkedRecords('edges')

//   console.log(edges)

//   cartItems.setLinkedRecord(newItem, )

//   // ConnectionHandler.insertEdgeAfter(cartItems, newItem)
// }

export default ( 
  productID,
  direction,
) => {
  const variables = {
    input: {
      productID,
      direction
    },
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        console.log(response)
      },
      onError: err => console.error(err),
    },
  );
}