/**
 * @flow
 * @relayHash 1f4a6bd9985f92fda80c0f31ecb54d44
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ShopDetailProduct_product$ref = any;
export type ScreenType = {
  screenWidth?: ?number,
  screenHeight?: ?number,
  viewPortHeight?: ?number,
  viewPortWidth?: ?number,
  pixelRatio?: ?number,
};
export type ShopDetailQueryVariables = {|
  slug?: ?string,
  screen?: ?ScreenType,
|};
export type ShopDetailQueryResponse = {|
  +viewer: ?{|
    +productByID: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +lookbookImages: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +original: string,
                +id: string,
              |}
            |}>
          |},
          +lookbookImagesMobile: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +original: string,
                +id: string,
              |}
            |}>
          |},
          +$fragmentRefs: ShopDetailProduct_product$ref,
        |}
      |}>
    |}
  |}
|};
export type ShopDetailQuery = {|
  variables: ShopDetailQueryVariables,
  response: ShopDetailQueryResponse,
|};
*/


/*
query ShopDetailQuery(
  $slug: String
  $screen: ScreenType
) {
  viewer(screen: $screen) {
    productByID(slug: $slug) {
      edges {
        node {
          ...ShopDetailProduct_product
          lookbookImages {
            edges {
              node {
                original
                id
              }
            }
          }
          lookbookImagesMobile {
            edges {
              node {
                original
                id
              }
            }
          }
          id
        }
      }
    }
    id
  }
}

fragment ShopDetailProduct_product on ProductNode {
  tax
  title
  id
  description
  available
  shippingInformation
  images {
    edges {
      node {
        original
        id
      }
    }
  }
  lookbookImagesMobile {
    edges {
      node {
        original
        id
      }
    }
  }
  children {
    edges {
      node {
        id
        title
        available
        shippingInformation
        attributeValues {
          edges {
            node {
              product {
                id
                title
              }
              productInStock {
                priceExclTax
                numInStock
                id
              }
              attribute {
                id
                name
              }
              attributeOption {
                id
                option
              }
              id
            }
          }
        }
      }
    }
  }
  productClass {
    name
    attributes {
      edges {
        node {
          id
          name
          optionGroup {
            options {
              edges {
                node {
                  id
                  option
                  description
                }
              }
            }
            id
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "screen",
    "type": "ScreenType",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "screen",
    "variableName": "screen",
    "type": "ScreenType"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug",
    "type": "String"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "original",
    "args": null,
    "storageKey": null
  },
  v3
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "lookbookImages",
  "storageKey": null,
  "args": null,
  "concreteType": "ProductLookbookImageNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductLookbookImageNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductLookbookImageNode",
          "plural": false,
          "selections": v4
        }
      ]
    }
  ]
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "lookbookImagesMobile",
  "storageKey": null,
  "args": null,
  "concreteType": "ProductLookbookImageMobileNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductLookbookImageMobileNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductLookbookImageMobileNode",
          "plural": false,
          "selections": v4
        }
      ]
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "available",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "shippingInformation",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "option",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ShopDetailQuery",
  "id": null,
  "text": "query ShopDetailQuery(\n  $slug: String\n  $screen: ScreenType\n) {\n  viewer(screen: $screen) {\n    productByID(slug: $slug) {\n      edges {\n        node {\n          ...ShopDetailProduct_product\n          lookbookImages {\n            edges {\n              node {\n                original\n                id\n              }\n            }\n          }\n          lookbookImagesMobile {\n            edges {\n              node {\n                original\n                id\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ShopDetailProduct_product on ProductNode {\n  tax\n  title\n  id\n  description\n  available\n  shippingInformation\n  images {\n    edges {\n      node {\n        original\n        id\n      }\n    }\n  }\n  lookbookImagesMobile {\n    edges {\n      node {\n        original\n        id\n      }\n    }\n  }\n  children {\n    edges {\n      node {\n        id\n        title\n        available\n        shippingInformation\n        attributeValues {\n          edges {\n            node {\n              product {\n                id\n                title\n              }\n              productInStock {\n                priceExclTax\n                numInStock\n                id\n              }\n              attribute {\n                id\n                name\n              }\n              attributeOption {\n                id\n                option\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n  productClass {\n    name\n    attributes {\n      edges {\n        node {\n          id\n          name\n          optionGroup {\n            options {\n              edges {\n                node {\n                  id\n                  option\n                  description\n                }\n              }\n            }\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ShopDetailQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": v1,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productByID",
            "storageKey": null,
            "args": v2,
            "concreteType": "ProductNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProductNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "ShopDetailProduct_product",
                        "args": null
                      },
                      v5,
                      v6
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ShopDetailQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": v1,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productByID",
            "storageKey": null,
            "args": v2,
            "concreteType": "ProductNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProductNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "tax",
                        "args": null,
                        "storageKey": null
                      },
                      v7,
                      v3,
                      v8,
                      v9,
                      v10,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "images",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProductImageNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProductImageNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ProductImageNode",
                                "plural": false,
                                "selections": v4
                              }
                            ]
                          }
                        ]
                      },
                      v6,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "children",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProductNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProductNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ProductNode",
                                "plural": false,
                                "selections": [
                                  v3,
                                  v7,
                                  v9,
                                  v10,
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "attributeValues",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ProductAttributeValueNodeConnection",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "edges",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "ProductAttributeValueNodeEdge",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "node",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "ProductAttributeValueNode",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "product",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "ProductNode",
                                                "plural": false,
                                                "selections": [
                                                  v3,
                                                  v7
                                                ]
                                              },
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "productInStock",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "StockRecordNode",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "priceExclTax",
                                                    "args": null,
                                                    "storageKey": null
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "numInStock",
                                                    "args": null,
                                                    "storageKey": null
                                                  },
                                                  v3
                                                ]
                                              },
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "attribute",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "ProductAttributeNode",
                                                "plural": false,
                                                "selections": [
                                                  v3,
                                                  v11
                                                ]
                                              },
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "attributeOption",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "AttributeOptionNode",
                                                "plural": false,
                                                "selections": [
                                                  v3,
                                                  v12
                                                ]
                                              },
                                              v3
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "productClass",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProductClassNode",
                        "plural": false,
                        "selections": [
                          v11,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "attributes",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProductAttributeNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ProductAttributeNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ProductAttributeNode",
                                    "plural": false,
                                    "selections": [
                                      v3,
                                      v11,
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "optionGroup",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "AttributeOptionGroupNode",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "options",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "AttributeOptionNodeConnection",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "edges",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "AttributeOptionNodeEdge",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "node",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "AttributeOptionNode",
                                                    "plural": false,
                                                    "selections": [
                                                      v3,
                                                      v12,
                                                      v8
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          },
                                          v3
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          v3
                        ]
                      },
                      v5
                    ]
                  }
                ]
              }
            ]
          },
          v3
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c046f27d023ff1cf5468a3f18dda9e08';
module.exports = node;
