import React, { PureComponent } from 'react';
import { createFragmentContainer } from 'react-relay';
import { withRelayContext } from 'containers/App';
import { graphql } from 'babel-plugin-relay/macro';
import MediaQuery from 'react-responsive';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import Image from 'components_simple/Image';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const choose = choices => {
  const index = Math.floor(Math.random() * choices.length);
  return choices[index];
};

const transformChoices = [
  'rotateZ(2deg) translate(0, -0.5vw) skew(1deg, 2deg)',
  'rotateZ(3deg) translate(0, -.5vw) skew(1deg, 2deg)',
  'rotateZ(-2deg) translate(0.5vw, -0.5vw) skew(0deg, 3deg)',
  'rotateZ(1deg) translate(0, -0.5vw) skew(0, 0)'
];

class Product extends PureComponent {
  state = {
    hover: false,
    style: ''
  };

  mouseEnter = () => {
    const style = choose(transformChoices);
    this.setState({
      hover: true,
      style
    });
  };

  mouseLeave = () => {
    this.setState({
      hover: false,
      style: ''
    });
  };

  render() {
    const { title, landingpageImage, children, slug } = this.props.product;

    let priceExclTax = 0;
    if (children.edges.length >= 1) {
      priceExclTax = children.edges[0].node.productInStock.priceExclTax;
    }

    const url = landingpageImage.edges[0].node.original || '';
    const { order } = this.props;
    const { hover, style } = this.state;
    const PcColumnHeight = order === 1 || order === 4 || order === 7 ? 'calc(100vh - 12vw)' : 'calc((100vh - 12vw)/2)';

    const material = children.edges.length > 1 ? 'Various materials' : 'One material'; // children.edges[0].node.title

    return (
      <div
        className={cx('main')}
        style={{ '--PcColumnHeight': PcColumnHeight, zIndex: 1000 + order }}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
      >
        <Link to={`/shop/${slug}`}>
          <MediaQuery maxWidth={1023}>
            <Image url={url} />
          </MediaQuery>
          <MediaQuery minWidth={1024}>
            <div className={styles.image} style={{ '--PcColumnHeight': PcColumnHeight, transform: style }}>
              <Image width="100%" height="100%" url={url} />
            </div>
            <div style={{ visibility: `${hover ? 'visible' : 'hidden'}` }} className={styles.productInfo}>
              <div style={{ paddingBottom: '0.2vw', fontSize: '1rem' }}>{title.toUpperCase()}</div>
              <div style={{ paddingBottom: '0.2vw', fontSize: '0.7rem' }}>{(priceExclTax * 1.19).toFixed(2)} €</div>
              <div style={{ paddingBottom: '0.2vw', fontSize: '0.7rem' }}>{material.toUpperCase()}</div>
            </div>
          </MediaQuery>
        </Link>
      </div>
    );
  }
}

export default withRelayContext(
  createFragmentContainer(
    Product,
    graphql`
      fragment Product_product on ProductNode {
        title
        slug
        landingpageImage {
          edges {
            node {
              original
              order
            }
          }
        }
        children {
          edges {
            node {
              title
              productInStock {
                priceExclTax
              }
            }
          }
        }
      }
    `
  )
);
