/**
 * @flow
 * @relayHash 207466fc59f96d3c40ec4abe6e37ba84
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ShopItemsProduct_product$ref = any;
export type ShopItemsRefetchQueryVariables = {|
  filter?: ?string
|};
export type ShopItemsRefetchQueryResponse = {|
  +viewer: ?{|
    +productByCategory: ?{|
      +productSet: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +title: string,
            +id: string,
            +$fragmentRefs: ShopItemsProduct_product$ref,
          |}
        |}>
      |}
    |},
    +productByCategories: ?$ReadOnlyArray<?{|
      +title: string,
      +id: string,
      +categories: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +name: string
          |}
        |}>
      |},
      +$fragmentRefs: ShopItemsProduct_product$ref,
    |}>,
  |}
|};
export type ShopItemsRefetchQuery = {|
  variables: ShopItemsRefetchQueryVariables,
  response: ShopItemsRefetchQueryResponse,
|};
*/


/*
query ShopItemsRefetchQuery(
  $filter: String
) {
  viewer {
    productByCategory(node: $filter) {
      productSet {
        edges {
          node {
            ...ShopItemsProduct_product
            title
            id
          }
        }
      }
      id
    }
    productByCategories(node: $filter) {
      ...ShopItemsProduct_product
      title
      id
      categories {
        edges {
          node {
            name
            id
          }
        }
      }
    }
    id
  }
}

fragment ShopItemsProduct_product on ProductNode {
  title
  slug
  images {
    edges {
      node {
        original
        id
      }
    }
  }
  children {
    edges {
      node {
        productInStock {
          priceExclTax
          id
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "filter",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "node",
    "variableName": "filter",
    "type": "String"
  }
],
v2 = {
  "kind": "FragmentSpread",
  "name": "ShopItemsProduct_product",
  "args": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "images",
  "storageKey": null,
  "args": null,
  "concreteType": "ProductImageNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductImageNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductImageNode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "original",
              "args": null,
              "storageKey": null
            },
            v4
          ]
        }
      ]
    }
  ]
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "children",
  "storageKey": null,
  "args": null,
  "concreteType": "ProductNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductNode",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "productInStock",
              "storageKey": null,
              "args": null,
              "concreteType": "StockRecordNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "priceExclTax",
                  "args": null,
                  "storageKey": null
                },
                v4
              ]
            },
            v4
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ShopItemsRefetchQuery",
  "id": null,
  "text": "query ShopItemsRefetchQuery(\n  $filter: String\n) {\n  viewer {\n    productByCategory(node: $filter) {\n      productSet {\n        edges {\n          node {\n            ...ShopItemsProduct_product\n            title\n            id\n          }\n        }\n      }\n      id\n    }\n    productByCategories(node: $filter) {\n      ...ShopItemsProduct_product\n      title\n      id\n      categories {\n        edges {\n          node {\n            name\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ShopItemsProduct_product on ProductNode {\n  title\n  slug\n  images {\n    edges {\n      node {\n        original\n        id\n      }\n    }\n  }\n  children {\n    edges {\n      node {\n        productInStock {\n          priceExclTax\n          id\n        }\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ShopItemsRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productByCategory",
            "storageKey": null,
            "args": v1,
            "concreteType": "CategoryNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "productSet",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProductNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProductNode",
                        "plural": false,
                        "selections": [
                          v2,
                          v3,
                          v4
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productByCategories",
            "storageKey": null,
            "args": v1,
            "concreteType": "ProductNode",
            "plural": true,
            "selections": [
              v2,
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "categories",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CategoryNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryNode",
                        "plural": false,
                        "selections": [
                          v5
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ShopItemsRefetchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productByCategory",
            "storageKey": null,
            "args": v1,
            "concreteType": "CategoryNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "productSet",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProductNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProductNode",
                        "plural": false,
                        "selections": [
                          v3,
                          v6,
                          v7,
                          v8,
                          v4
                        ]
                      }
                    ]
                  }
                ]
              },
              v4
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productByCategories",
            "storageKey": null,
            "args": v1,
            "concreteType": "ProductNode",
            "plural": true,
            "selections": [
              v3,
              v6,
              v7,
              v8,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "categories",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CategoryNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryNode",
                        "plural": false,
                        "selections": [
                          v5,
                          v4
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v4
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '53a29e92e85d8629356093764cf26826';
module.exports = node;
