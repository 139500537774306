import React, { PureComponent } from 'react';
import styles from './index.module.css';
import { createFragmentContainer } from 'react-relay';
import { withRelayContext, withPageIndex } from 'containers/App';
import { graphql } from 'babel-plugin-relay/macro';
import { Helmet } from 'react-helmet';

import AddHtml from 'components_simple/AddHtml';

class Imprint extends PureComponent {
  // componentDidMount() {
  //   this.props.changeTheme('dark');
  // }

  // componentWillUnmount() {
  //   this.props.changeTheme('bright');
  // }

  render() {
    return (
      <div className={styles.main}>
        <Helmet>
          <title>VEJOME - Imprint</title>
        </Helmet>
        <AddHtml html={this.props.viewer.category.description} />
        <div className={styles.fillDiv} />
      </div>
    );
  }
}

export default withPageIndex(
  withRelayContext(
    createFragmentContainer(
      Imprint,
      graphql`
        fragment Imprint_viewer on Viewer {
          category(node: "imprint") {
            name
            description
          }
        }
      `
    )
  )
);
