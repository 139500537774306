/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Menu_viewer$ref: FragmentReference;
export type Menu_viewer = {|
  +collections: ?$ReadOnlyArray<?{|
    +id: string,
    +name: string,
    +slug: string,
  |}>,
  +items: ?$ReadOnlyArray<?{|
    +name: string
  |}>,
  +$refType: Menu_viewer$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "Menu_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "collections",
      "name": "landingPage",
      "storageKey": null,
      "args": null,
      "concreteType": "RangeNode",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "slug",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "items",
      "name": "categoriesShop",
      "storageKey": "categoriesShop(node:\"items\")",
      "args": [
        {
          "kind": "Literal",
          "name": "node",
          "value": "items",
          "type": "String"
        }
      ],
      "concreteType": "CategoryNode",
      "plural": true,
      "selections": [
        v0
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b1b80077e1ae0f0af6c521ee0821fff4';
module.exports = node;
