import React, { PureComponent } from 'react';
import styles from './index.module.css';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { withPageIndex, withRelayContext, withShopFilterContext } from 'containers/App';

import ShoppingCart from 'containers/ShoppingCart';
import Menu from 'containers/Menu';
import DarkenBackground from 'components_simple/DarkenBackground';
import { ReactComponent as MenuIcon } from './burger.svg';
import { ReactComponent as Logo } from './logo_vejome.svg';
import {Helmet} from "react-helmet";

class Frame extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      viewCart: false,
      viewMenu: false,
      darkenVisibility: false
    };

    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen(item, action = 'open') {
    if (action === 'close') {
      this.setState({
        [item]: false
      });
      if (item === 'viewMenu') {
        this.props.updateViewMenu(false);
      } else if (item === 'viewCart') {
        this.props.updateViewCart(false);
      }
    } else {
      this.setState(prevState => {
        if (item === 'viewMenu') {
          this.props.updateViewMenu(!prevState[item]);
        } else if (item === 'viewCart') {
          this.props.updateViewCart(!prevState[item]);
        }
        return {
          [item]: !prevState[item]
        };
      });
    }
  }

  selectItem = data => {
    data = data.toLowerCase();
    this.setState({
      selected: data
    });
    this.props.updateShopFilter(data);
  };

  changeOpacity = () => {
    const { viewCart, viewMenu } = this.state;
    const opacity = viewMenu || viewCart ? 0.3 : 0.0;
    return opacity;
  };

  changeVisibility = () => {
    const { viewCart, viewMenu } = this.state;
    let visibility = '';
    if (viewMenu || viewCart) {
      visibility = 'visible';
    } else if (!viewMenu && !viewCart) {
      setTimeout(() => {
        visibility = 'hidden';
      }, 200);
    }

    return visibility;
  };

  render() {
    const { pageIndex, viewer } = this.props;
    const { items } = viewer;
    const { getTotalItems } = this.props.viewer.basket;
    const { viewCart, viewMenu } = this.state;

    return (
      <div className={styles.main}>
        <Helmet>
        <style>
            {`
              html, body {
                overflow: ${(viewMenu || viewCart) ? 'hidden' : 'initial'}
              }
            `}
          </style>
        </Helmet>
        <DarkenBackground in={viewCart || viewMenu} />
        <Menu
          eventTypes={['click', 'touchend']}
          show={viewMenu}
          toggleOpen={action => this.toggleOpen('viewMenu', action)}
        />
        <ShoppingCart
          eventTypes={['click', 'touchend']}
          show={viewCart}
          toggleOpen={action => this.toggleOpen('viewCart', action)}
        />
        <div className={styles.horizontalTop}>
          <MediaQuery minWidth={1024}>
            <Link to="/">
              <Logo fill="#9c9c9c" className={styles.logo} />
            </Link>
            <div className={styles.category}>
              {/* {(!viewMenu && !viewCart) && pageIndex === "home" && <span className={styles.shop}><Link to="/shop">SHOP</Link></span>} */}
              {!viewMenu &&
                !viewCart &&
                pageIndex === 'shop' &&
                items.map((item, index) => (
                  <Link key={index} to="/shop">
                    <span className={styles.item} onClick={() => this.selectItem(item.name)} key={index}>
                      <span
                        style={{
                          color: `${item.name.toLowerCase() === this.props.shopFilter.toLowerCase() ? '#4A4A4A' : ''}`
                        }}
                      >
                        {item.name}
                      </span>
                    </span>
                  </Link>
                ))}
            </div>
            <div className={styles.about}>
              <Link to="/about">ABOUT</Link>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={1023}>
            <div className={`${styles.menu} ignore-react-onclickoutside`} onClick={() => this.toggleOpen('viewMenu')}>
              {!viewMenu && <MenuIcon fill="green" className={styles.menu} />}
            </div>
            <Link to="/">{!viewMenu && !viewCart && <Logo className={styles.logo} />}</Link>
            <div className={`${styles.cart} ignore-react-onclickoutside`} onClick={() => this.toggleOpen('viewCart')}>
              {!viewCart && <div>({getTotalItems})</div>}
            </div>
          </MediaQuery>
        </div>
        <MediaQuery minWidth={1024}>
          <div className={styles.verticalLeft}>
            <div className={`${styles.menu} ignore-react-onclickoutside`} onClick={() => this.toggleOpen('viewMenu')}>
              SHOP
            </div>
          </div>
          <div className={styles.verticalRight}>
            <div className={`${styles.cart} ignore-react-onclickoutside`} onClick={() => this.toggleOpen('viewCart')}>
              CART ({getTotalItems})
            </div>
          </div>
        </MediaQuery>
        {this.props.children}
      </div>
    );
  }
}

export default withShopFilterContext(
  withPageIndex(
    withRelayContext(
      createFragmentContainer(
        Frame,
        graphql`
          fragment Frame_viewer on Viewer {
            items: categoriesShop(node: "items") {
              name
            }
            basket {
              getTotalItems
            }
          }
        `
      )
    )
  )
);
