import React from 'react'
import styles from './index.module.css'
import Transition from 'react-transition-group/Transition'

const duration = 1000

const transitionStyles = {
  entering: { opacity: 0 },
  entered:  { opacity: 0.3 },
  exiting: { opacity: 0 },
}

const DarkenBackground = ({ in: inProp}) => {
  return (
    <Transition in={inProp} timeout={{enter: 0, exit: duration}} unmountOnExit={true}>
      {(state) => (
        <div className={styles.main} style={{
          ...transitionStyles[state]
        }}>
        </div>
      )}
    
    </Transition>
  )
}

DarkenBackground.defaultProps = {
  in: false,
}

export default DarkenBackground