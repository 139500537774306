/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type Product_product$ref = any;
export type ProductLandingpageImageOrder = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type HomeGrid_grid$ref: FragmentReference;
export type HomeGrid_grid = {|
  +name: string,
  +description: string,
  +includedProducts: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +title: string,
        +landingpageImage: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +original: string,
              +order: ProductLandingpageImageOrder,
            |}
          |}>
        |},
        +$fragmentRefs: Product_product$ref,
      |}
    |}>
  |},
  +$refType: HomeGrid_grid$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "HomeGrid_grid",
  "type": "RangeNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "includedProducts",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ProductNode",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "Product_product",
                  "args": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "title",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "landingpageImage",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ProductLandingpageImageNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ProductLandingpageImageNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ProductLandingpageImageNode",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "original",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "order",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'd6759494ba9f1eb9cc829fc53c7e6e5f';
module.exports = node;
