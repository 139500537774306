import React, { PureComponent } from 'react';
import styles from './index.module.css';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Image from 'components_simple/Image';

// import Circle from './carousel_dot.svg';

import { ReactComponent as Circle } from './carousel_dot.svg';

const duration = 500;

class ImageCarousel extends PureComponent {
  state = {
    currentImageIndex: this.props.currentImageIndex,
    changeIn: true
  };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentImageIndex !== prevProps.currentImageIndex) {
      this.setState({
        currentImageIndex: this.props.currentImageIndex
      });
    }
  }

  selectImage = index => {
    this.setState({
      currentImageIndex: index
    });
  };

  nextImage = () => {
    const { images } = this.props;
    const { currentImageIndex } = this.state;
    const lastImage = images.length === currentImageIndex + 1;
    this.setState(prevState => {
      if (lastImage) {
        return {
          currentImageIndex: 0
        };
      } else {
        return {
          currentImageIndex: prevState.currentImageIndex + 1
        };
      }
    });
  };

  lastImage = () => {
    const { images } = this.props;
    const { currentImageIndex } = this.state;
    const firstImage = currentImageIndex === 0;
    const imagesLastIndex = images.length - 1;

    this.setState(prevState => {
      if (firstImage) {
        return {
          currentImageIndex: imagesLastIndex
        };
      } else {
        return {
          currentImageIndex: prevState.currentImageIndex - 1
        };
      }
    });
  };

  render() {
    const { images, splitClick, arrows, selector, containNumber } = this.props;
    const { currentImageIndex } = this.state;

    const clickWidth = !splitClick ? '100%' : '50%';

    return (
      <div className={styles.main}>
        <TransitionGroup component={null} childFactory={child => React.cloneElement(child)}>
          {(images &&
            images
              .map((image, index) => {
                const fit = index + 1 <= containNumber ? 'contain' : 'cover';

                return (
                  <CSSTransition
                    appear={true}
                    key={image && image.node && image.node.id}
                    timeout={{ enter: 0, exit: duration }}
                    classNames={{
                      appear: styles['transition-appear'],
                      enter: styles['transition-enter'],
                      enterActive: styles['transition-enter-active'],
                      enterDone: styles['transition-enter-active'],
                      exit: styles['transition-exit'],
                      exitActive: styles['transition-exit-active'],
                      exitDone: styles['transition-exit-done']
                    }}
                  >
                    {state => (
                      <div className={styles.slider}>
                        {arrows && <div className={styles.leftArrow}>{'<'}</div>}
                        {arrows && <div className={styles.rightArrow}>{'>'}</div>}
                        <div onClick={this.nextImage} style={{ width: `${clickWidth}` }} className={styles.right} />
                        {splitClick && <div onClick={this.lastImage} className={styles.left} />}
                        <Image fit={fit} url={image && image.node && image.node.original} />
                      </div>
                    )}
                  </CSSTransition>
                );
              })
              .filter((image, index) => {
                return index === currentImageIndex;
              })) ||
            null}
        </TransitionGroup>
        {selector && (
          <div className={styles.selector}>
            {images.map((image, index) => {
              const selectedImage = index === currentImageIndex ? 0.2 : 1;
              return (
                <span
                  key={index}
                  style={{ opacity: `${selectedImage}` }}
                  className={styles.item}
                  onClick={() => this.selectImage(index)}
                >
                  {''}
                  <Circle className={styles.circle} />
                </span>
              );
            }) || null}
          </div>
        )}
      </div>
    );
  }
}

ImageCarousel.defaultProps = {
  splitClick: false,
  arrows: false,
  firstContain: false,
  containNumber: 0,
  selector: true,
  currentImageIndex: 0
};

export default ImageCarousel;
