import React, { PureComponent } from 'react';
import { createRefetchContainer } from 'react-relay';
import { withRelayContext, withShopFilterContext, withPageIndex } from 'containers/App';
import { graphql } from 'babel-plugin-relay/macro';
import styles from './index.module.css';

import ShopItemsProduct from 'containers/ShopItemsProduct';

class ShopItems extends PureComponent {
  state = {};

  componentDidUpdate(prevProps) {
    if (this.props.shopFilter !== prevProps.shopFilter) {
      // this._refetch();
    }
  }

  _refetch = () => {
    this.props.relay.refetch(
      { filter: this.props.shopFilter },
      null,
      () => {
        // console.log('refetch done');
      },
      { force: false }
    );
  };

  componentDidMount() {
    this.props.updatePageIndex('shop');
  }

  render() {
    const { productByCategories } = this.props.viewer;

    const filterProducts = productByCategories.filter(item => {
      const filter = item.categories.edges.filter(node => {
        const filter = this.props.shopFilter.toLowerCase();
        return node.node.name.toLowerCase() === filter || filter === 'all';
      });
      return filter.length > 0;
    });
    // .map((item) => {
    //   let newItem = Object.assign({}, item, {
    //     node: {
    //       ...item.node,
    //       attributeValues: {
    //         edges: item.node.attributeValues.edges.filter((node) => {
    //           return (node.node.attribute.name.toLowerCase() === "material")
    //         })
    //       }
    //     }
    //   })
    //   return newItem
    // })

    return (
      <div className={styles.main}>
        {productByCategories !== undefined &&
          filterProducts.map((product, index) => <ShopItemsProduct key={index} product={product} />)}
      </div>
    );
  }
}

export default withPageIndex(
  withShopFilterContext(
    withRelayContext(
      createRefetchContainer(
        ShopItems,
        graphql`
          fragment ShopItems_viewer on Viewer {
            productByCategory(node: "all") {
              productSet {
                edges {
                  node {
                    ...ShopItemsProduct_product
                    title
                    id
                  }
                }
              }
            }
            productByCategories(node: "all") {
              ...ShopItemsProduct_product
              title
              id
              categories {
                edges {
                  node {
                    name
                  }
                }
              }
            }
          }
        `,
        graphql`
          query ShopItemsRefetchQuery($filter: String) {
            viewer {
              productByCategory(node: $filter) {
                productSet {
                  edges {
                    node {
                      ...ShopItemsProduct_product
                      title
                      id
                    }
                  }
                }
              }
              productByCategories(node: $filter) {
                ...ShopItemsProduct_product
                title
                id
                categories {
                  edges {
                    node {
                      name
                    }
                  }
                }
              }
            }
          }
        `
      )
    )
  )
);

// export default withRelayContext(withShopFilterContext(withLoadingContext(createFragmentContainer(
//   ShopItems,
//   graphql`
//   fragment ShopItems_viewer on Viewer @argumentDefinitions(
//     filter: {type: "String", defaultValue: "all items"}
//   ) {
//     productByCategories(node: $filter) {
//       ...ShopItemsProduct_product
//       title
//       id
//     }
//   }
//   `
// ))))
