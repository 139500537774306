import React, { PureComponent } from 'react'
import styles from './index.module.css'
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro'
import MediaQuery from 'react-responsive'
import environment from 'environment'

import ShopDetailProduct from 'containers/ShopDetailProduct'
import ImageCarousel from 'containers/ImageCarousel';
import { withPageIndex } from 'containers/App'

const screen = () => {
  const screenHeight = window.screen.height
  const screenWidth = window.screen.width
  const viewPortHeight = window.innerHeight
  const viewPortWidth = window.innerWidth
  const pixelRatio = window.devicePixelRatio

  return {
    screenHeight: screenHeight, 
    screenWidth: screenWidth,
    viewPortHeight : viewPortHeight,
    viewPortWidth : viewPortWidth,
    pixelRatio: pixelRatio,
  }
}

class ShopDetail extends PureComponent {
    state = {
        slug: this.props.match.params.itemID,
    }

    // componentDidMount() {
    //   this.props.updatePageIndex('shopDetail')
    // }

    // _refetch = () => {
    //   this.props.relay.refetch(
    //     {slug: this.props.match.params.itemID},  // Our refetchQuery needs to know the `itemID`
    //     null,  // We can use the refetchVariables as renderVariables
    //     () => { console.log('Refetch done') },
    //     {force: true},  // Assuming we've configured a network layer cache, we want to ensure we fetch the latest data.
    //   );
    // }

    render() {
        return ( 
        <QueryRenderer environment = { environment }
            query = { graphql `query ShopDetailQuery (
              $slug: String
              $screen: ScreenType
              ) {
              viewer (screen: $screen) {
                productByID(slug: $slug) {
                  edges {
                    node {
                      ...ShopDetailProduct_product
                      lookbookImages {
                        edges {
                          node {
                            original
                            id
                          }
                        }
                      }
                      lookbookImagesMobile {
                        edges {
                          node {
                            original
                            id
                          }
                        }
                      }
                    }
                }
              }
            }
          }` }
            variables = {
                {
                    slug: this.state.slug,
                    test: 'test',
                    screen: screen(),
                }
            }
            render = {
                ({ error, props }) => {
                    if (error) {
                        console.log(error)
                        return <div> Error! </div>;
                    }
                    if (!props) {
                        return null // <div><LoadingScreen /></div>;
                    }

                    const { edges } = props.viewer.productByID

                    // let image = false

                    // if (edges.length === 1) {
                    //     let { original } = edges[0].node.lookbookImages.edges[0].node
                    //     image = <ImageRelative url={ original }
                    //     fit="cover">
                    // }

                    return( 
                      <div className={ styles.main }>
                        <MediaQuery minWidth={1024}>
                          <div className={ styles.left }>
                            <ImageCarousel images={edges[0].node.lookbookImages.edges}/>
                          </div>
                        </MediaQuery> 
                        <div className={ styles.right }>
                          <ShopDetailProduct product={ edges[0].node }/> 
                        </div> 
                      </div>
                    );
                }
            }
          />
        )
    }
}

export default withPageIndex(ShopDetail)

// export default withRelayContext(createRefetchContainer(
//   ShopDetail,
//   {viewer: graphql`
//   fragment ShopDetail_viewer on Viewer @argumentDefinitions(
//     slug: {type: "String", defaultValue: ""}
//   ) {
//     productByID(slug_Iexact: $slug) {
//       edges {
//         node {
//           ...ShopDetailProduct_product
//           lookbookImages {
//             edges {
//               node {
//                 original
//               }
//             }
//           }
//         }
//       }
//     }
//   }
//   `
//   },
//   graphql`
//     query ShopDetailRefetchQuery($slug: String) {
//       viewer {
//         ...ShopDetail_viewer @arguments(slug: $slug)
//       }
//     }
//   `
// ))