/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type HomeLookBook_lookbook$ref: FragmentReference;
export type HomeLookBook_lookbook = {|
  +landingpageLookbookImages: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +original: string,
        +id: string,
      |}
    |}>
  |},
  +landingpageLookbookImagesMobile: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +original: string,
        +id: string,
      |}
    |}>
  |},
  +$refType: HomeLookBook_lookbook$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "original",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "HomeLookBook_lookbook",
  "type": "RangeNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "landingpageLookbookImages",
      "storageKey": null,
      "args": null,
      "concreteType": "LandingpageLookbookImageNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "LandingpageLookbookImageNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "LandingpageLookbookImageNode",
              "plural": false,
              "selections": v0
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "landingpageLookbookImagesMobile",
      "storageKey": null,
      "args": null,
      "concreteType": "LandingpageLookbookImageMobileNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "LandingpageLookbookImageMobileNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "LandingpageLookbookImageMobileNode",
              "plural": false,
              "selections": v0
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f2c1b627e3cdfdc4f29c8387e5fd7dc7';
module.exports = node;
