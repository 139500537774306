/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ShopItemsProduct_product$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ShopItems_viewer$ref: FragmentReference;
export type ShopItems_viewer = {|
  +productByCategory: ?{|
    +productSet: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +title: string,
          +id: string,
          +$fragmentRefs: ShopItemsProduct_product$ref,
        |}
      |}>
    |}
  |},
  +productByCategories: ?$ReadOnlyArray<?{|
    +title: string,
    +id: string,
    +categories: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +name: string
        |}
      |}>
    |},
    +$fragmentRefs: ShopItemsProduct_product$ref,
  |}>,
  +$refType: ShopItems_viewer$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "node",
    "value": "all",
    "type": "String"
  }
],
v1 = {
  "kind": "FragmentSpread",
  "name": "ShopItemsProduct_product",
  "args": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ShopItems_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "productByCategory",
      "storageKey": "productByCategory(node:\"all\")",
      "args": v0,
      "concreteType": "CategoryNode",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "productSet",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductNodeConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "ProductNodeEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ProductNode",
                  "plural": false,
                  "selections": [
                    v1,
                    v2,
                    v3
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "productByCategories",
      "storageKey": "productByCategories(node:\"all\")",
      "args": v0,
      "concreteType": "ProductNode",
      "plural": true,
      "selections": [
        v1,
        v2,
        v3,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "categories",
          "storageKey": null,
          "args": null,
          "concreteType": "CategoryNodeConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "CategoryNodeEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CategoryNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '92ef1b0ef345c599b2ff950393b844e2';
module.exports = node;
