import React from 'react'
import { Switch, Route } from 'react-router-dom';
import { withPageIndex } from 'containers/App'
import {Helmet} from "react-helmet";

import styles from './index.module.css'

import ShopItems from 'containers/ShopItems'
import ShopDetail from 'containers/ShopDetail'

const Shop = props => {
  return <div className={styles.main}>
        <Helmet>
          <title>VEJOME - Shop</title>
        </Helmet>
        <Switch>
          <Route exact path='/shop' component={ShopItems} />
          <Route path='/shop/:itemID' component={ShopDetail} slug="TEST" />
        </Switch>
      </div>;
};

export default withPageIndex(Shop)
